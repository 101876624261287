import Icon1 from "../../../global-assets/assets/Signup.webp";
import movie from "../assets/movie.webp";
import game from "../assets/game.webp";

export const ParentsHubData = [
  {
    icon: movie,
    title: "Snow White and the Seven Dwarfs",
    description:
      "A new live action spin on the classic fairytale. Should you let your kids watch it?",
    url: "https://www.truplaygames.com/resources/posts/parents-guide-to-disneys-new-snow-white-is-it-too-woke",
  },
  {
    icon: game,
    title: "Doom Eternal",
    description:
      "The new smash hit from the creators of the Doom series. Should you let your kids play it?",
    url: "https://www.truplaygames.com/resources/posts/a-king-david-bible-trivia-quiz",
  },
  {
    icon: game,
    title: "Call of Duty: Black Ops 6",
    description:
      "The latest installment in the Call of Duty series. Should you let your kids play it?",
    url: "https://www.truplaygames.com/resources/posts/a-king-david-bible-trivia-quiz",
  },
  {
    icon: game,
    title: "Marvel Rivals",
    description:
      "What exactly is Marvel Rivals and what you should know before letting your kids play it.",
    url: "https://www.truplaygames.com/resources/posts/is-marvel-rivals-a-game-for-kids-parents-guide-to-marvel-rivals-truplay-games",
  },
];
