import {
  GetEmailAddress,
  isAuthenticatedWithValidToken,
} from "../../../config/UserAuthContext";

// Components
import NavBar from "../NavBar/NavBar";
import GiftASubNavbar from "../GiftASubNavbar/GiftASubNavbar";
import TruPlayAppsNav from "../TruPlayAppsNav/TruPlayAppsNav";
import MinimalNav from "../MinimalNav";
import NotificationBanner from "../../common/NotificationBanner";

// Assets //
import RaisedHandsLeftDesktop from "../../../global-assets/assets/raised-hands-banner-left-desktop.webp";
import RaisedHandsRightDesktop from "../../../global-assets/assets/raised-hands-banner-right-desktop.webp";
import RaisedHandsLeftMobile from "../../../global-assets/assets/raised-hands-banner-left-mobile.webp";
import RaisedHandsRightMobile from "../../../global-assets/assets/raised-hands-banner-right-mobile.webp";
import { Link } from "react-router-dom";

interface TopNavBarProps {
  navType?: string;
  showMobileLogo?: boolean;
  showBanner?: boolean;
}

/**
 *
 * @constructor
 */
const TopNavbar = ({
  navType,
  showMobileLogo = true,
  showBanner = false,
}: TopNavBarProps) => {
  let isAuthenticated = isAuthenticatedWithValidToken();
  const EmailAddress: string = GetEmailAddress() || "";

  let content = null;

  if (navType === "gift") {
    content = (
      <GiftASubNavbar
        isAuthenticated={isAuthenticated}
        emailAddress={EmailAddress}
      />
    );
  } else if (navType === "truplay-apps") {
    content = <TruPlayAppsNav />;
  } else if (navType === "minimal") {
    content = (
      <MinimalNav
        isAuthenticated={isAuthenticated}
        emailAddress={EmailAddress}
      />
    );
  } else {
    content = (
      <NavBar
        isAuthenticated={isAuthenticated}
        emailAddress={EmailAddress}
        showMobileLogo={showMobileLogo}
        light={navType === "light"}
      />
    );
  }

  return (
    <>
      <link href="https://fonts.googleapis.com" rel="preconnect" />
      <link
        href="https://fonts.gstatic.com"
        rel="preconnect"
        crossOrigin="anonymous"
      />
      <div
        className={
          "fixed bottom-auto left-0 right-0 top-0 z-[999]" +
          (navType !== "light" ? " bg-global_nav_bg" : " bg-white")
        }
      >
        {showBanner && (
          <NotificationBanner
            leftBGImageMoblie={RaisedHandsLeftMobile}
            rightBGImageMoblie={RaisedHandsRightMobile}
            leftBGImageDesktop={RaisedHandsLeftDesktop}
            rightBGImageDesktop={RaisedHandsRightDesktop}
            textComponent={
              <p className="m-0 text-center font-body text-lg font-medium text-white md:justify-end md:text-2xl">
                Check out our all new game -<br className="lg:hidden" />
                <span>
                  {" "}
                  <Link
                    className="cursor-pointer font-display text-lg font-bold text-white underline hover:drop-shadow-[0px_0px_16px_rgba(255,255,255,75%)] md:text-2xl"
                    to="/games/lucas-mission-starhope"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Lucas: Mission StarHope
                  </Link>
                </span>
              </p>
            }
          />
        )}
        <div className="mx-auto max-w-[1536px]">{content}</div>
      </div>
    </>
  );
};

export default TopNavbar;
