import { useEffect, useState } from "react";
import React from "react";
import TruPlayLogo from "../../../global-assets/assets/truplay-logo-large.webp";
import {
  GetOrCreateSessionAuthContext,
  navigateTo,
} from "../../../config/UserAuthContext";
import TagManager from "react-gtm-module";
import FooterAlt from "./FooterAlt";
import { useFeatureFlags } from "../../../config/FeatureFlagContext";
import Cookies from "js-cookie";
import Button from "../../common/Button";
import AppStoreLinks from "../../home-landing/AppStoreLinks";

interface FooterProps {
  alt?: boolean;
  thankYou?: boolean;
}

const Footer = ({ alt = false, thankYou = false }: FooterProps) => {
  const osType = navigator.userAgent;
  const userContext = GetOrCreateSessionAuthContext();
  const featureFlags = useFeatureFlags();
  const FreeTrial30 = featureFlags["2024-04 - 30 Day Free Trial"]?.variationId;
  const [freeTrial30, setFreeTrial30] = useState(false);
  const GlobalUserId = Cookies.get("GlobalUserId");

  useEffect(() => {
    if (FreeTrial30) {
      switch (FreeTrial30) {
        case "1":
          break;
        case "2":
          setFreeTrial30(true);
          break;
      }
    }
  }, [FreeTrial30]);

  const handleGtagClick =
    (linkURL: string) =>
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      const linkName = e.currentTarget.innerText;

      TagManager.dataLayer({
        dataLayer: {
          event: "select_content",
          content_type: "menu",
          os_type: osType,
          link_name: linkName,
          link_url: linkURL,
          plan_type: userContext.subscription.sources[0]?.productId ?? "none",
          custom_user_id: GlobalUserId || undefined,
        },
      });
    };

  const handleTeamLinkClick = () => {
    if (window.location.pathname === "/about") {
      const section = document.getElementById("team-section");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigateTo("/about#team-section");
    }
  };

  const handleCTAClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "select_content",
        os_type: osType,
        content_type: "button cta",
        link_name: "Footer-CTA",
        link_url: "/signup",
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    navigateTo("/signup");
  };

  if (alt) {
    return <FooterAlt />;
  }

  return (
    <div className="bg-indigo-900 px-5 py-12 sm:px-6 md:px-7 lg:max-h-[272px] lg:px-12  lg:py-14 lg:pb-20">
      <div className="mx-auto flex max-w-[1440px] flex-col items-center justify-between gap-10 lg:flex-row">
        <div className="flex h-full min-w-[240px] flex-col items-center justify-center bg-indigo-900 lg:items-baseline">
          <div className="mb-3 max-w-[150px] sm:max-w-[193px]">
            <img src={TruPlayLogo} loading="lazy" alt="" />
          </div>
          <div className="mb-5 hidden text-center font-display text-sm font-medium text-white lg:mb-8 lg:inline lg:text-left">
            <div>
              &copy; TruPlay. All Rights Reserved.
              <br />
              <span
                id="privacy_policy_link"
                onClick={(e) => {
                  handleGtagClick("/privacy-policy");
                  navigateTo("/privacy-policy");
                }}
                className="cursor-pointer text-white underline"
              >
                Privacy Policy
              </span>
              .{" "}
              <span
                id="terms_and_conditions_link"
                onClick={(e) => {
                  handleGtagClick("/terms-and-conditions");
                  navigateTo("/terms-and-conditions");
                }}
                className="cursor-pointer text-white underline"
              >
                Terms &amp; Conditions
              </span>
              .
            </div>
          </div>
          <div className="hidden text-center font-display text-xs font-medium text-white lg:inline lg:text-left">
            <div>Content and platform availability may vary by region.</div>
          </div>
        </div>
        <div className="flex h-full min-w-[240px] flex-col items-center justify-center bg-indigo-900 lg:items-baseline">
          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 md:grid-cols-4 xl:flex xl:w-full xl:gap-6">
            <span
              id="footer_games_more_link"
              className="cursor-pointer text-center font-body text-lg font-bold text-white no-underline sm:text-left"
              onClick={(e) => {
                handleGtagClick("/games");
                navigateTo("/games");
              }}
            >
              Games
            </span>
            <a
              id="footer_merchandise_link"
              href="https://truplaygames.shop/"
              rel="noreferrer"
              target="_blank"
              className="cursor-pointer text-center font-body text-lg font-bold text-white no-underline sm:text-left"
              onClick={() => handleGtagClick("https://truplaygames.shop/")}
            >
              Merchandise
            </a>
            <span
              id="footer_about_link"
              className="cursor-pointer text-center font-body text-lg font-bold text-white no-underline sm:text-left"
              onClick={(e) => {
                handleGtagClick("/about");
                navigateTo("/about");
              }}
            >
              Who We Are
            </span>
            <span
              id="footer_team_link"
              className="cursor-pointer text-center font-body text-lg font-bold text-white no-underline sm:text-left"
              onClick={(e) => {
                handleGtagClick("/about#team-section");
                handleTeamLinkClick();
              }}
            >
              Our Team
            </span>
            {/* <a
              id="footer_news_link"
              href="/news"
              rel="noreferrer"
              className="cursor-pointer text-center font-body text-lg font-bold text-white no-underline sm:text-left"
              onClick={() => handleGtagClick("/news")}
            >
              News
            </a>
            <a
              id="footer_blog_link"
              href="/blog"
              rel="noreferrer"
              className="cursor-pointer text-center font-body text-lg font-bold text-white no-underline sm:text-left"
              onClick={() => handleGtagClick("/blog")}
            >
              Blog
            </a> */}
            <a
              id="footer_news_link"
              href="https://www.truplaygames.com/resources/news"
              rel="noreferrer"
              target="_blank"
              className="cursor-pointer text-center font-body text-lg font-bold text-white no-underline sm:text-left"
              onClick={() => handleGtagClick("https://www.truplaygames.com/resources/news")}
            >
              News
            </a>
            <a
              id="footer_blog_link"
              href="https://www.truplaygames.com/resources/blog"
              rel="noreferrer"
              target="_blank"
              className="cursor-pointer text-center font-body text-lg font-bold text-white no-underline sm:text-left"
              onClick={() => handleGtagClick("https://www.truplaygames.com/resources/blog")}
            >
              Blog
            </a>
            <span
              id="footer_careers_link"
              className="cursor-pointer text-center font-body text-lg font-bold text-white no-underline sm:text-left"
              onClick={(e) => {
                handleGtagClick("/careers");
                navigateTo("/careers");
              }}
            >
              Careers
            </span>
            <span
              id="footer_contact_link"
              className="cursor-pointer text-center font-body text-lg font-bold text-white no-underline sm:text-left"
              onClick={(e) => {
                handleGtagClick("/contact");
                navigateTo("/contact");
              }}
            >
              Contact
            </span>
            <span
              id="footer_faq_link"
              className="cursor-pointer text-center font-body text-lg font-bold text-white no-underline sm:text-left"
              onClick={(e) => {
                handleGtagClick("/faq");
                navigateTo("/faq");
              }}
            >
              FAQ
            </span>
          </div>
        </div>
        <div className="flex h-full min-w-[240px] flex-col items-center justify-center gap-6 bg-indigo-900 lg:hidden lg:items-baseline">
          {thankYou && <AppStoreLinks nowrap />}
          {!thankYou && (
            <Button
              id="footer_cta_btn"
              onClick={handleCTAClick}
              btnType="green"
            >
              {freeTrial30
                ? "Start 30 Day Free Trial"
                : "Redeem Your Free Week"}
            </Button>
          )}
          <div className="mb-5 text-center font-display text-sm font-medium text-white lg:mb-8 lg:hidden lg:text-left">
            <div>
              &copy; TruPlay. All Rights Reserved.
              <br />
              <span
                id="privacy_policy_link_mobile"
                onClick={(e) => {
                  handleGtagClick("/privacy-policy");
                  navigateTo("/privacy-policy");
                }}
                className="cursor-pointer text-white underline"
              >
                Privacy Policy
              </span>
              .{" "}
              <span
                id="terms_and_conditions_link_mobile"
                onClick={(e) => {
                  handleGtagClick("/terms-and-conditions");
                  navigateTo("/terms-and-conditions");
                }}
                className="cursor-pointer text-white underline"
              >
                Terms &amp; Conditions
              </span>
              .
            </div>
          </div>
          <div className="text-center font-display text-xs font-medium text-white lg:hidden lg:text-left">
            <div>Content and platform availability may vary by region.</div>
          </div>
        </div>
        <div className="text-center">
          <a
            href="https://www.kidsafeseal.com/certifiedproducts/truplay_app.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{ border: 0 }}
              width="150"
              alt="TruPlay: Play Christian Games app is a member of the kidSAFE Seal Program."
              src="https://www.kidsafeseal.com/sealimage/14385588753829552/truplay_app_svg_whitetm.svg"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
