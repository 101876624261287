import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { postService } from "../../config/PostService";
import { Post } from "../../lib/Post";
import PageHeaderButton from "../../components/common/PageHeaderButton";
import Button from "../../components/common/Button"

// Images
import newsbg from "../../global-assets/assets/newsbg.webp";

export default function BlogList() {
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const itemsPerPage = 10;

  const fetchPosts = async (page: number) => {
    try {
      setLoadingMore(page > 1);
      const { posts: newPosts, total } = await postService.getAllPosts(page, itemsPerPage);
      
      if (page === 1) {
        setPosts(newPosts);
      } else {
        setPosts(prevPosts => [...prevPosts, ...newPosts]);
      }
      
      // Check if we've loaded all available posts
      setHasMore(posts.length + newPosts.length < total);
    } catch (err) {
      setError("Failed to load blog posts");
      console.error("Error fetching posts:", err);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    fetchPosts(1);
  }, []);

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    fetchPosts(nextPage);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="mx-auto">
      <PageHeaderButton
        backgroundImage={newsbg}
        title="Blog Posts"
        isActive={true}
      />
      <div className="mx-auto mt-10 grid max-w-7xl gap-6 px-4 md:grid-cols-2 lg:grid-cols-3">
        {posts.map((post) => (
          <Link
            key={post._id}
            to={`/blog/${post.slug.current}`}
            className="group block overflow-hidden rounded-lg bg-white shadow-md transition-transform hover:-translate-y-1 hover:shadow-lg"
          >
            {post.mainImage && (
              <div className="relative h-48 overflow-hidden">
                <img
                  src={post.mainImage.asset.url}
                  alt={post.title}
                  className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-105"
                />
              </div>
            )}
            <div className="p-4">
              <h2 className="mb-2 text-xl font-semibold text-gray-900">
                {post.title}
              </h2>
              <div className="flex items-center gap-2 text-sm text-gray-600">
                {post.author && (
                  <div className="flex items-center gap-2">
                    {post.author.image && (
                      <img
                        src={post.author.image.asset.url}
                        alt={post.author.name}
                        className="h-6 w-6 rounded-full"
                      />
                    )}
                    <span>{post.author.name}</span>
                  </div>
                )}
                <span>•</span>
                <span>{new Date(post.publishedAt).toLocaleDateString()}</span>
              </div>
              {post.categories && post.categories.length > 0 && (
                <div className="mt-3 flex flex-wrap gap-2">
                  {post.categories.map((category) => (
                    <span
                      key={category._id}
                      className="rounded bg-cerulean-500/20 px-2 py-1 text-xs text-cerulean-600"
                    >
                      {category.title}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </Link>
        ))}
      </div>
      
      {hasMore && (
        <div className="flex justify-center mt-8 mb-12">
          <Button
            onClick={handleLoadMore}
            disabled={loadingMore}
            btnType="green"
            className="px-6 py-2 bg-cerulean-600 text-white rounded-md hover:bg-cerulean-700 transition-colors disabled:bg-gray-400"
          >
            {loadingMore ? "Loading..." : "Load More"}
          </Button>
        </div>
      )}
    </div>
  );
}
