import { useContext, useEffect, useState, useRef } from "react";
import { DeviceSessionContext } from "../../../components/analytics/DeviceContext";
import Utm from "../../../components/analytics/UTM";
import { MixPanelAnalyticsContext } from "../../../components/analytics/Analytics";
import TagManager from "react-gtm-module";
import {
  GetOrCreateSessionAuthContext,
  navigateTo,
} from "../../../config/UserAuthContext";
import Cookies from "js-cookie";

// Components //
import PageHeaderButton from "../../../components/common/PageHeaderButton";
import ImageInfoSection from "../../../components/common/ImageInfoSection";
import CardsCTA from "../../../components/common/CardsCTA";
import EndorsementsSection from "../../../components/home-landing/Endorsements/Endorsements";
import AppReviewSlider from "../../../components/common/AppReviewsSlider";

// Images //
import dadSon from "../../../global-assets/assets/Dad-Son.webp";
import AffiliateIndHeader from "../../../global-assets/assets/AffiliateIndHeader.webp";
import AppGameTiles from "../../../global-assets/assets/App_Game_Tiles.webp";
import ReviewStar from "../../../global-assets/assets/gold-review-star.webp";

// Data //
import { AffiliateMarketerCardsData } from "../../../lib/MarketingData";

function reviewStars() {
  const stars: any[] = [];
  for (let i = 0; i < 5; i++) {
    stars.push(<img key={i} alt="star" src={ReviewStar} className="max-w-6" />);
  }
  return stars;
}

const AffiliateMarketer = () => {
  const mixPanelAnalytics = useContext(MixPanelAnalyticsContext);
  const [deviceCtx] = useState<DeviceSessionContext>(
    new DeviceSessionContext()
  );
  const initialUserContext = GetOrCreateSessionAuthContext();

  const [userContext] = useState(initialUserContext);
  const GlobalUserId = Cookies.get("GlobalUserId");

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        page_name: "marketing: Affiliate Marketer",
        page_type: "marketing",
        os_type: deviceCtx.os.name,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    let utm = new Utm(mixPanelAnalytics);
    utm.track("Web: Affiliate-Marketer-Page-Viewed", deviceCtx);
  }, [
    deviceCtx,
    mixPanelAnalytics,
    userContext.subscription.sources,
    GlobalUserId,
  ]);

  const ctaRef: any = useRef(null);

  const handleGetStartedClick = () => {
    if (ctaRef.current) {
      const topPosition =
        ctaRef.current.getBoundingClientRect().top + window.pageYOffset;
      const offset = 100;
      window.scrollTo({ top: topPosition - offset, behavior: "smooth" });
    }

    TagManager.dataLayer({
      dataLayer: {
        event: "select_content",
        content_type: "link",
        link_name: "becomeAnAffiliateSectionMarketer",
        os_type: deviceCtx.os.name,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });
  };

  const ctaClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "select_content",
        content_type: "link",
        link_name: "getRewardfulMarketer",
        link_url:
          "https://app.impact.com/campaign-promo-signup/TruPlay-Games-Inc.brand?execution=e1s1&#/?viewkey=signUpPreStart",
        os_type: deviceCtx.os.name,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    const url =
      "https://app.impact.com/campaign-promo-signup/TruPlay-Games-Inc.brand?execution=e1s1&#/?viewkey=signUpPreStart";

    window.open(url, "_blank", "noopener, noreferrer");
  };

  const learnMoreClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "select_content",
        content_type: "link",
        link_name: "Learn More Affiliate Marketer",
        link_url: "/games",
        os_type: deviceCtx.os.name,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    navigateTo("/games");
  };

  return (
    <div>
      <div
        id="test_data_Affiliate_Marketer_page"
        className="min-h-screen overflow-x-hidden bg-cerulean-100"
      >
        <PageHeaderButton
          backgroundImage={AffiliateIndHeader}
          isActive={true}
          title="Become a TruPlay Affiliate."
          buttonLabel="Get Started"
          onButtonClick={handleGetStartedClick}
        />
        <ImageInfoSection
          image={dadSon}
          title="Become a TruPlay Affiliate"
          description="Apply for the TruPlay Affiliate program and use your marketing skills to earn generous commissions on every TruPlay subscription that you generate."
          imagePosition="right"
        />
        <div ref={ctaRef} />
        <CardsCTA
          buttonLabel="Become an Affiliate"
          onButtonClick={ctaClick}
          cardsData={AffiliateMarketerCardsData}
        />
        <div className="flex flex-col gap-12 bg-white pt-20">
          <div className="flex flex-wrap items-center justify-center gap-4">
            <div className="flex gap-1">{reviewStars()}</div>
            <br className="md:hidden" />
            <p className="text-transparent m-0 bg-gradient_text bg-clip-text text-lg font-semibold">
              100s of Verified 5-star Reviews
            </p>
          </div>
          <div className="flex flex-col gap-4">
            <h2 className="text-transparent bg-gradient_text bg-clip-text text-center text-3xl lg:text-5xl">
              Testimonials
            </h2>
            <p className="text-center font-normal text-cool-gray-900">
              See what parents are saying about the all new Christian
              entertainment platform.
            </p>
          </div>
          <AppReviewSlider noTitle lightBackground />
        </div>
        <div className="bg-white px-4 pb-20 md:p-20">
          <div className="mx-auto max-w-[1440px]">
            <EndorsementsSection backgroundColor="bg-indigo-700" />
          </div>
        </div>
        <ImageInfoSection
          image={AppGameTiles}
          title="Want more information about TruPlay?"
          description="Discover the exciting world of TruPlay - a hub for passionate gamers and affiliates alike. Find out how TruPlay is revolutionizing the gaming industry, providing a seamless and rewarding experience for all players."
          imagePosition="right"
          buttonText="Learn More"
          imageWidth="w-[450px]"
          onButtonClick={learnMoreClick}
          buttonWidth="w-[90%]"
        />
      </div>
    </div>
  );
};

export default AffiliateMarketer;
