import { useContext, useState } from "react";
import AppstoreImg from "../../global-assets/assets/AppStore_img.svg";
import GoogleplayImg from "../../global-assets/assets/GooglePlay_img.svg";
import BGLandingImage from "../../global-assets/assets/BG_Image_Landing.webp";
import WebImageHero2 from "../../global-assets/assets/Web_Image_Hero-2.webp";
import { Link } from "react-router-dom";
import { EnvironmentConfigContext } from "../../config/Environment";
import Utm from "../../components/analytics/UTM";
import { MixPanelAnalyticsContext } from "../../components/analytics/Analytics";
import { DeviceSessionContext } from "../../components/analytics/DeviceContext";
import TextWithContentSplit from "../../components/common/TextWithContentSplit";
import SectionHeader from "../../components/common/SectionHeader";

const TruPlayAppsLanding = () => {
  const config = useContext(EnvironmentConfigContext);
  const analyticsContext = useContext(MixPanelAnalyticsContext);

  const [deviceCtx] = useState<DeviceSessionContext>(
    new DeviceSessionContext()
  );

  const trackDownloads = (selectedStore: string) => {
    let utm = new Utm(analyticsContext);
    utm.track(
      `Web: Marketing-Page-Download-Clicked-${selectedStore}`,
      deviceCtx
    );
  };

  const contentText = (
    <div className="flex flex-col gap-6">
      <p className="m-0 text-center text-white lg:text-left">
        Provide your family with Bible-based games and Christian content they
        love, while you enjoy a safe platform with no ads, in-app purchases, or
        chat features to worry about.
      </p>
      <div className="flex flex-col items-center justify-start gap-8 lg:flex-row">
        <Link
          id="apple-store-link"
          to={config.AppStoreURL()}
          target="_blank"
          onClick={() => {
            trackDownloads("AppleAppStore");
          }}
          className=""
        >
          <img src={AppstoreImg} alt="Apple store" className="max-h-16" />
        </Link>
        <Link
          id="google-play-store-link"
          to={config.PlayStoreURL()}
          target="_blank"
          onClick={() => {
            trackDownloads("GooglePlay");
          }}
          className=""
        >
          <img src={GoogleplayImg} alt="Google play" className="max-h-16" />
        </Link>
        <br />
      </div>
    </div>
  );

  return (
    <div id="test_data_truplay_apps" className="min-h-[calc(100vh-272px)]">
      <div className="relative overflow-hidden">
        <div className="mx-auto flex max-w-[1440px] flex-col items-center justify-center gap-8 px-5 pb-16 pt-28 sm:px-6 md:gap-12 md:px-7 lg:gap-32 lg:py-32">
          <div className="lg:hidden">
            <SectionHeader
              headerType="h1"
              headerText="TruPlay: Christian Games and Content for the whole family"
            />
          </div>
          <TextWithContentSplit
            headerType="h1"
            headerText="TruPlay: Christian Games and Content for the whole family"
            hideHeaderOnMobile
            subtext={contentText}
            content={<img src={WebImageHero2} loading="lazy" alt="" />}
            subtextColor="text-white"
            contentLocation="right"
            textPositionOnMobile="below"
          />
        </div>
      </div>
      <div className="absolute bottom-auto left-0 right-0 top-0 -z-[3] mx-auto max-w-none overflow-hidden regmd:flex">
        <img
          src={BGLandingImage}
          loading="eager"
          sizes="100vw"
          alt=""
          className="relative -z-[1] w-full min-w-[1920px]"
        />
      </div>
    </div>
  );
};

export default TruPlayAppsLanding;
