// Packages
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SEO from "../../components/common/SEO";
import Cookies from "js-cookie";
import TagManager from "react-gtm-module";
import ApiInstance from "../../lib/ApiFunction";

// Context
import UserAuthContext, {
  Context,
  GetOrCreateSessionAuthContext,
  GetRememberMe,
} from "../../config/UserAuthContext";
import { ErrorCodeMap } from "../../lib/Constants";
import { ContinueTo, GetContinueToQueryString } from "../../lib/PassedParams";
import { useRemoteConfig } from "../../config/FBRemoteConfigContext";
import { useAnalytics } from "../../hooks/useAnalytics";

// Components
import Button from "../../components/common/Button";
import { InlineErrorMessage } from "../../components/notification/InlineErrorMessage/InlineErrorMessage";
import TopNavbar from "../../components/nav/TopNavbar/TopNavbar";

// Assets
import EmailIcon from "../../global-assets/assets/icon-email.svg";
import PasswordIcon from "../../global-assets/assets/icon-password.svg";
import eyeShowIcon from "../../global-assets/assets/icon-eye-show.svg";
import eyeHideIcon from "../../global-assets/assets/icon-eye-hide.svg";
import BackCaret from "../RegistrationFlow/assets/back-caret.svg";

// Firebase
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { AxiosResponse } from "axios";

interface LoginProps {
  setAuth: (value: boolean) => void;
  setLoginData: (data: any) => void;
  setIsEmailLogIn?: (value: boolean) => void;
}

/**
 *
 * @param setAuth
 * @param setLoginData
 * @constructor
 */
export default function Login({
  setAuth,
  setLoginData,
  setIsEmailLogIn,
}: LoginProps) {
  const analytics = useAnalytics();
  let failedLoginAttempts: number = 0;

  const navigate = useNavigate();

  const { AccountServiceUserLogin } = ApiInstance();
  const emailInputRef: any = useRef(null);
  const passwordInputRef: any = useRef(null);

  const [email, setEmail] = useState<any>("");
  const [password, setPassword] = useState<any>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [isButtonActive, setIsButtonActive] = useState<boolean>(false);
  const [isCallingAPI, setIsCallingAPI] = useState<boolean>(false);
  const [isRememberMe, setIsRememberMe] = useState<boolean>(false);
  const [isPasswordAutofilled, setIsPasswordAutofilled] =
    useState<boolean>(false);
  const osType = navigator.userAgent;

  const userContext = GetOrCreateSessionAuthContext();
  const subscriptionInfo = userContext.subscription.sources;
  const GlobalUserId = Cookies.get("GlobalUserId");

  const [loginError, setLoginError] = useState<string | null>(null);
  const [isEmailError, setIsEmailError] = useState<boolean>(false);
  const [isPasswordError, setIsPasswordError] = useState<boolean>(false);

  const [isCookiesBlocked, setIsCookiesBlocked] = useState<boolean>(false);

  const credsFromCookie: any = GetRememberMe();

  const firebaseFlags = useRemoteConfig();

  Cookies.set(Context.FreeTrial, "true");

  useEffect(() => {
    console.log("firebaseFlags.firebaseSSO", firebaseFlags);
    const cookiesAvailable = () => {
      try {
        localStorage.setItem("test", "test");
        localStorage.removeItem("test");
        setIsCookiesBlocked(false);
        return true;
      } catch (e) {
        setIsCookiesBlocked(true);
        TagManager.dataLayer({
          dataLayer: {
            event: "cookies_error_shown",
            cookies_consent: "cookies_blocked",
            page_name: "Login",
            page_type: "Marketing",
            custom_user_id: GlobalUserId || undefined,
          },
        });
        return false;
      }
    };

    if (cookiesAvailable() && process.env.REACT_APP_ENVIRONMENT === "prod") {
      const script = document.createElement("script");
      script.src =
        "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=STmu7s";
      script.async = true;
      script.type = "text/javascript";
      document.body.appendChild(script);

      return () => {
        if (document.body.contains(script)) {
          document.body.removeChild(script);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (credsFromCookie) {
      const credentials = JSON.parse(credsFromCookie);
      if (credentials) {
        const { email } = credentials;
        setEmail(email);
        setIsRememberMe(true);
      }
    }
  }, [credsFromCookie]);

  useEffect(() => {
    if (passwordInputRef.current && passwordInputRef.current.value) {
      setIsPasswordAutofilled(true);
    }
  }, [password]);

  useEffect(() => {
    if (!password.length || !email.length) {
      setIsButtonActive(false);
    } else {
      setIsButtonActive(true);
    }
  }, [email.length, password.length]);

  useEffect(() => {
    analytics.trackEvent("page_view", {
      page_name: "login",
      os_type: osType,
      plan_type: userContext.subscription.sources[0]?.productId ?? "none",
      custom_user_id: GlobalUserId || undefined,
    });
  }, []);

  useEffect(() => {
    if (!auth) {
      analytics.trackAuthError(
        "login",
        "initialization",
        "none",
        "Firebase auth not initialized",
        {
          os_type: osType,
          custom_user_id: GlobalUserId || undefined,
        }
      );
    }
  }, [GlobalUserId, analytics, osType]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Firebase Auth
  const handleFirebaseLogIn = () => {
    // Handle Remember Me
    if (isRememberMe) {
      Cookies.set(Context.RememberMe, JSON.stringify({ email: email }));
    } else {
      Cookies.remove(Context.RememberMe);
    }

    setIsEmailError(false);
    setIsPasswordError(false);
    setLoginError("");

    setIsCallingAPI(true);

    analytics.trackAuthAttempt("login", "email", email, {
      os_type: osType,
      plan_type: subscriptionInfo[0] || "none",
      custom_user_id: GlobalUserId || undefined,
    });

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        analytics.trackAuthSuccess("login", "email", email, {
          os_type: osType,
          plan_type: subscriptionInfo[0] || "none",
          custom_user_id: GlobalUserId || undefined,
        });

        // Signed in
        const user: any = userCredential.user;
        Cookies.set(
          Context.AuthContext,
          JSON.stringify({ userID: user.uid, token: user.accessToken })
        );

        const userData: any = {
          communicationsOptIn: false,
          subscription: {
            isActive: false,
            expireTime: null,
            sources: Array(0),
          },
          token: user.accessToken,
          uuId: user.uid,
          verifyEmail: {
            isVerified: user.emailVerified,
            shouldShowEmailVerificationScreen: !user.emailVerified,
          },
        };
        Cookies.set(Context.UserContext, JSON.stringify(userData));
        Cookies.set("EmailAddress", email);

        // User is authenticated
        setAuth(true);

        setLoginData(userData);
        analytics.trackEvent("login_success", {
          method: "email",
          os_type: osType,
          email: email,
          plan_type: subscriptionInfo[0] || "none",
          custom_user_id: GlobalUserId || undefined,
        });

        TagManager.dataLayer({
          dataLayer: {
            event: "login",
            plan_type: subscriptionInfo[0] || "none",
            os_type: osType,
            method: "email",
            custom_user_id: GlobalUserId || undefined,
          },
        });

        const currentAuthContext: UserAuthContext = userData;

        // look for 'continueto' param, go there instead if we have one
        if (ContinueTo()) {
          analytics.trackEvent("redirect_after_authentication", {
            page_name: "login",
            os_type: osType,
            plan_type: subscriptionInfo[0] || "none",
            custom_user_id: GlobalUserId || undefined,
          });
          navigate(decodeURIComponent(ContinueTo() || ""));
        } else if (currentAuthContext.subscription.isActive) {
          analytics.trackEvent("account_setting_viewed", {
            page_name: "login",
            os_type: osType,
            plan_type: subscriptionInfo[0] || "none",
            custom_user_id: GlobalUserId || undefined,
          });
          navigate("/account-settings");
        } else {
          analytics.trackEvent("select_plan_viewed", {
            page_name: "login",
            os_type: osType,
            plan_type: subscriptionInfo[0] || "none",
            custom_user_id: GlobalUserId || undefined,
          });
          navigate("/pricing");
        }

        setIsCallingAPI(false);
      })
      .catch((error) => {
        const errorMessage = ErrorCodeMap[error.code] || error.message;

        analytics.trackAuthError("login", "email", email, errorMessage, {
          os_type: osType,
          plan_type: subscriptionInfo[0] || "none",
          custom_user_id: GlobalUserId || undefined,
        });

        setLoginError(errorMessage);
        setIsCallingAPI(false);
      });
  };

  /**
   * Authenticate user
   * @param e
   */
  const handleOnSubmit = (e: any) => {
    // Handle Remember Me
    if (isRememberMe) {
      Cookies.set(Context.RememberMe, JSON.stringify({ email: email }));
    } else {
      Cookies.remove(Context.RememberMe);
    }

    setIsEmailError(false);
    setIsPasswordError(false);
    setLoginError("");

    let userCredentials = {
      emailAddress: email,
      password: password,
    };

    setIsCallingAPI(true);

    AccountServiceUserLogin.post("", userCredentials)
      .then((response: AxiosResponse) => {
        let { uuId, token } = response.data;

        Cookies.set(
          Context.AuthContext,
          JSON.stringify({ userID: uuId, token: token })
        );

        Cookies.set(Context.UserContext, JSON.stringify(response.data));

        Cookies.set("EmailAddress", email);

        // user is authenticated
        setAuth(true);

        setLoginData(response.data);
        analytics.trackEvent("Web: Login-Attempt-Successful", {
          method: "email",
          os_type: osType,
          email: email,
          plan_type: subscriptionInfo[0] || "none",
          custom_user_id: GlobalUserId || undefined,
        });

        TagManager.dataLayer({
          dataLayer: {
            event: "login",
            plan_type: subscriptionInfo[0] || "none",
            os_type: osType,
            method: "email",
            custom_user_id: GlobalUserId || undefined,
          },
        });

        let currentAuthContext: UserAuthContext = response.data;

        if (ContinueTo()) {
          analytics.trackEvent("Web: Pricing-Viewed", {
            page_name: "login",
            os_type: osType,
            plan_type: subscriptionInfo[0] || "none",
            custom_user_id: GlobalUserId || undefined,
          });
          navigate(decodeURIComponent(ContinueTo() || ""));
        } else if (currentAuthContext.subscription.isActive) {
          analytics.trackEvent("Web: Account-Setting-Viewed", {
            page_name: "login",
            os_type: osType,
            plan_type: subscriptionInfo[0] || "none",
            custom_user_id: GlobalUserId || undefined,
          });
          navigate("/account-settings");
        } else {
          analytics.trackEvent("Web: Pricing-Viewed", {
            page_name: "login",
            os_type: osType,
            plan_type: subscriptionInfo[0] || "none",
            custom_user_id: GlobalUserId || undefined,
          });
          navigate("/pricing");
        }
      })
      .catch((error: any) => {
        setIsCallingAPI(false);

        if (
          error.response?.data.statusCode in
          ["AS005", "AS001", "AS300", "AS301"]
        ) {
          Cookies.set(
            "LoginAttempts",
            JSON.stringify({
              "last-attempt-ts": new Date().toUTCString(),
              "failed-attempts": failedLoginAttempts,
            })
          );
          analytics.trackEvent(
            `Web: Login-Attempt-Failed-${failedLoginAttempts}`,
            {
              method: "email",
              os_type: osType,
              email: email,
              error_message: error.response?.data.statusMessage,
              plan_type: subscriptionInfo[0] || "none",
              custom_user_id: GlobalUserId || undefined,
            }
          );
        }

        let errorMessage = ErrorCodeMap[error.response?.data.statusCode]
          ? ErrorCodeMap[error.response?.data.statusCode]
          : error.response?.data.statusMessage;
        setLoginError(errorMessage);
        console.error(error.response?.data.statusMessage);
      });
  };

  return (
    <>
      <div className="hidden regmd:block">
        <TopNavbar />
      </div>
      <div className="regmd:hidden">
        <TopNavbar
          navType="light"
          showMobileLogo={firebaseFlags.firebaseSSO ? false : true}
        />
      </div>
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] min-h-screen bg-white regmd:hidden" />
      <div className="tru-single-modal-bg absolute bottom-0 left-0 right-0 top-0 -z-[1] hidden opacity-60 regmd:block" />
      <div
        id="test_data_login_page"
        className="relative h-full min-h-[calc(100vh-272px)] regmd:flex regmd:items-center regmd:justify-center regmd:py-44"
      >
        <SEO
          title="Login | Access Your Account | Truplay Games"
          description="Log in to your Truplay Games account to access games and manage your subscription."
          canonicalUrl="https://accounts.truplaygames.com/login"
        />
        {typeof setIsEmailLogIn === "function" && firebaseFlags.firebaseSSO ? (
          <img
            alt="Back"
            src={BackCaret}
            loading="eager"
            className="absolute left-6 top-10 z-[1000] h-6 w-6 rotate-90 cursor-pointer opacity-80 hover:opacity-100 sm:top-9 sm:h-8 sm:w-8 regmd:hidden"
            onClick={() => setIsEmailLogIn(false)}
          />
        ) : null}
        <form
          className={
            "relative mx-auto flex min-h-screen max-w-[450px] flex-col gap-4 px-6 pb-0 pt-32" +
            " regmd:min-h-[unset] regmd:w-[515px] regmd:max-w-none regmd:gap-6 regmd:rounded-[2rem]" +
            " regmd:bg-white regmd:px-10 regmd:py-8"
          }
        >
          {typeof setIsEmailLogIn === "function" &&
          firebaseFlags.firebaseSSO ? (
            <img
              alt="Back"
              src={BackCaret}
              loading="eager"
              className="absolute left-8 top-8 z-[101] hidden h-8 w-8 rotate-90 cursor-pointer opacity-80 hover:opacity-100 regmd:!block"
              onClick={() => setIsEmailLogIn(false)}
            />
          ) : null}
          <h4 className="text-center text-3xl text-indigo-500">Log In</h4>
          <p className="m-0 text-center">
            Do you have a TruPlay Games Account?
          </p>
          <div>
            <div className="relative flex w-full flex-col gap-2">
              <div className="relative flex w-full items-center">
                <img
                  className="absolute left-2.5 z-[3] w-6"
                  src={EmailIcon}
                  alt=""
                  loading="eager"
                />
                <input
                  id="login_email"
                  className={
                    "relative flex w-full rounded-lg border-0 bg-cool-gray-200" +
                    " px-2.5 py-4 text-left text-base text-indigo-500 outline-none" +
                    " placeholder:text-cool-gray-900/60 focus:bg-cool-gray-300" +
                    " indent-8 focus:ring-2 focus:ring-cerulean-500 focus:placeholder:text-cool-gray-900/80" +
                    (isEmailError || isEmailError
                      ? " ring-2 ring-raspberry-500"
                      : "") +
                    (isCookiesBlocked ? " cursor-not-allowed opacity-50" : "")
                  }
                  ref={emailInputRef}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="Email"
                  type="email"
                  disabled={isCookiesBlocked}
                />
              </div>
              <div className="relative flex w-full items-center">
                <img
                  className="absolute left-2.5 z-[3] w-6"
                  src={PasswordIcon}
                  alt=""
                  loading="eager"
                />
                <input
                  id="login_password"
                  className={
                    "relative flex w-full rounded-lg border-0 bg-cool-gray-200" +
                    " px-2.5 py-4 text-left text-base text-indigo-500 outline-none" +
                    " placeholder:text-cool-gray-900/60 focus:bg-cool-gray-300" +
                    " indent-8 focus:ring-2 focus:ring-cerulean-500 focus:placeholder:text-cool-gray-900/80" +
                    (isPasswordError || isPasswordError
                      ? " ring-2 ring-raspberry-500"
                      : "") +
                    (isCookiesBlocked ? " cursor-not-allowed opacity-50" : "")
                  }
                  ref={passwordInputRef}
                  value={password}
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  autoComplete="off"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (!isPasswordAutofilled && e.target.value) {
                      setIsPasswordAutofilled(true);
                    }
                  }}
                  disabled={isCookiesBlocked}
                />
                <div className="absolute right-1.5 top-4">
                  <div
                    className="flex items-center justify-center"
                    role="button"
                    onClick={togglePasswordVisibility}
                  >
                    {password ? (
                      showPassword ? (
                        <img
                          src={eyeShowIcon}
                          alt="Show Password"
                          loading="lazy"
                          className="w-[22px] cursor-pointer text-cerulean-500"
                        />
                      ) : (
                        <img
                          src={eyeHideIcon}
                          alt="Hide Password"
                          loading="lazy"
                          className="w-[22px] cursor-pointer text-cerulean-500"
                        />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="ml-1 flex justify-end pt-1 text-right text-base font-semibold capitalize no-underline">
              <Link
                to="/forgot-password"
                className="mb-2 text-cerulean-500 hover:underline"
              >
                Forgot?
              </Link>
            </div>
            {loginError && <InlineErrorMessage errorMessage={loginError} />}
            {isCookiesBlocked && (
              <InlineErrorMessage
                errorMessage={
                  "Oh no! You blocked all cookies, so you won't " +
                  "be able to see anything. You must enable cookies " +
                  "in your browser settings to be able to log in. " +
                  "Once you do, give the page a refresh."
                }
              />
            )}
            <div
              className={
                "flex justify-start" +
                (loginError || isCookiesBlocked ? " mt-3" : " mt-1")
              }
            >
              <div className="flex items-center">
                <input
                  id="remember_me_checkbox"
                  type="checkbox"
                  onChange={(e) => setIsRememberMe(e.target.checked)}
                  checked={isRememberMe}
                />
                <label
                  className={
                    "ml-3 text-sm" +
                    (isRememberMe
                      ? " text-cool-gray-900"
                      : " text-cool-gray-900/50")
                  }
                >
                  Remember me
                </label>
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col gap-4 px-6 pb-8 pt-4 regmd:p-0">
            <Button
              id="log_in_page_submit"
              loading={isCallingAPI}
              disabled={!isButtonActive}
              onClick={
                firebaseFlags.firebaseAuth
                  ? handleFirebaseLogIn
                  : handleOnSubmit
              }
              btnType="green"
              className="w-full"
              formType="submit"
            >
              Log In
            </Button>
            <div className="flex items-center justify-center text-center">
              <span className="inline-block p-0.5 text-base font-medium text-cool-gray-900">
                Don't have an account?
              </span>
              <Link to={"/signup" + GetContinueToQueryString()}>
                <Button
                  id="log_in_sign_up_cta"
                  btnType="tertiaryAlt"
                  className="ml-2 !text-base hover:underline active:!text-cerulean-500"
                >
                  Sign Up
                </Button>
              </Link>
            </div>
            <div className="pt-5 text-center sm:hidden">
              <a
                href="https://www.kidsafeseal.com/certifiedproducts/truplay_app.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{ border: 0 }}
                  width="150"
                  alt="TruPlay: Play Christian Games app is a member of the kidSAFE Seal Program."
                  src="https://www.kidsafeseal.com/sealimage/14385588753829552/truplay_app_svg_whitetm.svg"
                />
              </a>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
