// Packages
import { useState, useContext } from "react";
import Cookies from "js-cookie";

// Context
import {
  Context,
  GetOrCreateSessionAuthContext,
} from "../../config/UserAuthContext";
import { ErrorCodeMap } from "../../lib/Constants";
import { MixPanelAnalyticsContext } from "../../components/analytics/Analytics";

// Components
import UserRegistration from "./UserRegistration";
import RegNavUnauth from "../../components/nav/RegNavUnauth/RegNavUnauth";
import Button from "../../components/common/Button";
import BackgroundGradientMain from "../../components/common/BackgroundGradientMain";

// Assets
import BGLandingImage from "../../global-assets/assets/BG_Image_Landing.webp";
import GoogleLogo from "./assets/logo-google.svg";

// Firebase
import {
  GoogleAuthProvider,
  signInWithPopup,
  getAdditionalUserInfo,
} from "firebase/auth";
import { auth } from "../../firebase";
import { useRemoteConfig } from "../../config/FBRemoteConfigContext";
import { InlineErrorMessage } from "../../components/notification/InlineErrorMessage/InlineErrorMessage";
import { PulseLoader } from "react-spinners";
import { useAnalytics } from "../../hooks/useAnalytics";
import TagManager from "react-gtm-module";

/**
 * Login Page to authenticate users with valid email address on file
 *
 * @param props
 * @constructor
 */
interface LoginProps {
  setAuth: any;
  setLoginData: any;
}

export default function SSOSignUp({ setAuth, setLoginData }: LoginProps) {
  const analytics = useAnalytics();
  const firebaseFlags = useRemoteConfig();
  const provider = new GoogleAuthProvider();
  const [isEmailSignUp, setIsEmailSignUp] = useState<boolean>(false);
  const [isCallingAPI, setIsCallingAPI] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string | null>(null);
  const analyticsContext = useContext(MixPanelAnalyticsContext);

  const osType = navigator.userAgent;
  const GlobalUserId = Cookies.get("GlobalUserId");
  const userContext = GetOrCreateSessionAuthContext();
  const subscriptionInfo = userContext.subscription.sources;

  const handleGoogleSignUp = () => {
    setIsCallingAPI(true);

    analytics.trackAuthAttempt("sign_up", "google", "", {
      os_type: osType,
      plan_type: subscriptionInfo[0] || "none",
      custom_user_id: GlobalUserId || undefined,
    });

    signInWithPopup(auth, provider)
      .then((result) => {
        const additionalUserInfo = getAdditionalUserInfo(result);
        const isNewUser = additionalUserInfo?.isNewUser;
        const isAnonymous = result.user.isAnonymous;
        const authMethod = isAnonymous ? "anonymous" : "google";

        if (isNewUser) {
          analyticsContext.track("Web: Account-Creation-Successful", {
            auth_method: authMethod,
            is_new_user: true,
          });
        } else {
          analyticsContext.track("Web: Account-Login-Successful", {
            auth_method: authMethod,
            is_new_user: false,
          });
        }

        let attribution = localStorage.getItem("tru-attribution") as string;
        let campaignAttribution = {};
        if (attribution) {
          campaignAttribution = JSON.parse(attribution);
        }

        analyticsContext.track("Web: Account-Creation-Successful", {
          ...campaignAttribution,
          auth_method: authMethod,
          is_new_user: isNewUser || false,
        });

        analytics.trackAuthSuccess("sign_up", authMethod, "", {
          os_type: osType,
          plan_type: subscriptionInfo[0] || "none",
          custom_user_id: GlobalUserId || undefined,
          is_new_user: isNewUser || false,
        });

        // The signed-in user info.
        const user = result.user;
        user
          .getIdToken()
          .then((accessToken) => {
            Cookies.set(
              Context.AuthContext,
              JSON.stringify({ userID: user.uid, token: accessToken })
            );
            const userData: any = {
              communicationsOptIn: false,
              subscription: {
                isActive: false,
                expireTime: null,
                sources: Array(0),
              },
              token: accessToken,
              uuId: user.uid,
              verifyEmail: {
                isVerified: user.emailVerified,
                didSendNewVerificationEmail: false,
                shouldShowEmailVerificationScreen: false,
              },
              sso: true,
            };

            Cookies.set(Context.UserContext, JSON.stringify(userData));
            Cookies.set("EmailAddress", user.email || "");
            Cookies.set(Context.FreeTrial, "true");

            analytics.trackEvent("sign_up", {
              method: "google",
              os_type: osType,
              plan_type: subscriptionInfo[0] || "none",
              custom_user_id: GlobalUserId || undefined,
            });

            TagManager.dataLayer({
              dataLayer: {
                event: "sign_up",
                method: "google",
                plan_type: subscriptionInfo[0] || "none",
                os_type: osType,
                custom_user_id: GlobalUserId || undefined,
              },
            });
          })
          .catch((error) => {
            const errorMessage = ErrorCodeMap[error.code] || error.message;

            analytics.trackAuthError("sign_up", "google", "", errorMessage, {
              os_type: osType,
              plan_type: subscriptionInfo[0] || "none",
              custom_user_id: GlobalUserId || undefined,
            });

            setLoginError(errorMessage);
            setIsCallingAPI(false);
          });
        setLoginData(user);
        setAuth(true);
        setIsCallingAPI(false);
      })
      .catch((error) => {
        const errorMessage = ErrorCodeMap[error.code] || error.message;

        analytics.trackAuthError("sign_up", "google", "", errorMessage, {
          os_type: osType,
          plan_type: subscriptionInfo[0] || "none",
          custom_user_id: GlobalUserId || undefined,
        });

        setLoginError(errorMessage);
        setIsCallingAPI(false);
      });
  };

  if (isCallingAPI || firebaseFlags.firebaseSSO === undefined) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-white regmd:bg-indigo-900">
        <div className="hidden regmd:block">
          <PulseLoader color="#fff" />
        </div>
        <div className="regmd:hidden">
          <PulseLoader color="#003E7A" />
        </div>
      </div>
    );
  }

  if (!firebaseFlags.firebaseSSO) {
    return <UserRegistration setAuth={setAuth} setLoginData={setLoginData} />;
  }

  if (isEmailSignUp) {
    return (
      <UserRegistration
        setAuth={setAuth}
        setLoginData={setLoginData}
        setIsEmailSignUp={setIsEmailSignUp}
      />
    );
  }

  return (
    <>
      <RegNavUnauth />
      <BackgroundGradientMain />
      <div className="tru-register-flow-blue-bg absolute bottom-0 left-0 right-0 top-0 -z-[2] hidden opacity-40 regmd:block" />
      <div className="tru-register-flow-middle-bg absolute bottom-0 left-0 right-0 top-0 -z-[1] hidden opacity-50 regmd:block" />
      <div
        id="test_data_registration_page"
        className="min-h-screen regmd:flex regmd:items-center regmd:justify-center regmd:py-44"
      >
        <div
          className={
            "relative mx-auto flex min-h-screen flex-col gap-4 px-6 pb-0 pt-32 regmd:min-h-[unset]" +
            " regmd:w-[600px] regmd:gap-6 regmd:rounded-[2rem] regmd:bg-white regmd:px-10 regmd:py-8"
          }
        >
          <h4 className="hidden text-center text-4xl text-indigo-500 regmd:block">
            Create Account
          </h4>
          <h4 className="mt-4 text-center text-2xl text-indigo-500 sm:text-3xl regmd:hidden">
            Create Your Account
          </h4>
          <p className="mx-auto mb-0 max-w-[200px] text-center font-normal text-cool-gray-900 sm:max-w-[225px] regmd:max-w-none">
            Play across multiple devices with a single account
          </p>
          <div className="flex w-full flex-col gap-4 px-6 pb-8 pt-4 regmd:static regmd:p-0">
            <Button
              id="sign_up_google_btn"
              onClick={handleGoogleSignUp}
              btnType="googleSSO"
              className="w-full"
              leftIcon={
                <img
                  src={GoogleLogo}
                  alt="Google"
                  className="h-7 w-7 regmd:h-8 regmd:w-8"
                />
              }
            >
              Continue With Google
            </Button>
            <Button
              id="log_in_email_pass_btn"
              onClick={() => setIsEmailSignUp(true)}
              btnType="secondaryAlt"
              className="w-full regmd:h-14"
            >
              Sign Up With Email
            </Button>
            {loginError && <InlineErrorMessage errorMessage={loginError} />}
            <label className="mx-auto max-w-[375px] text-center text-sm font-normal text-gray-900">
              By Proceeding you are agreeing to our{" "}
              <a
                className="font-body text-sm font-bold text-cerulean-500 no-underline hover:underline"
                rel="noreferrer"
                href="/terms-and-conditions"
                target="_blank"
              >
                Terms of Service{" "}
              </a>
              and{" "}
              <a
                className="font-body text-sm font-bold text-cerulean-500 no-underline hover:underline"
                rel="noreferrer"
                href="/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </a>
            </label>
            <div className="pt-5 text-center sm:hidden">
              <a
                href="https://www.kidsafeseal.com/certifiedproducts/truplay_app.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{ border: 0 }}
                  width="150"
                  alt="TruPlay: Play Christian Games app is a member of the kidSAFE Seal Program."
                  src="https://www.kidsafeseal.com/sealimage/14385588753829552/truplay_app_svg_whitetm.svg"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="absolute bottom-auto left-0 right-0 top-0 -z-[3] mx-auto max-w-none overflow-hidden regmd:flex">
          <img
            src={BGLandingImage}
            loading="eager"
            sizes="100vw"
            alt=""
            className="relative -z-[1] w-full min-w-[1920px]"
          />
        </div>
      </div>
    </>
  );
}
