// src/hooks/useAnalytics.ts
import { useContext } from "react";
import TagManager from "react-gtm-module";
import { MixPanelAnalyticsContext } from "../components/analytics/Analytics";
import { GA4AnalyticsContext } from "../components/analytics/Analytics";

export const useAnalytics = () => {
  const mixPanelAnalytics = useContext(MixPanelAnalyticsContext);
  const ga4Context = useContext(GA4AnalyticsContext);

  const trackEvent = (eventName: string, properties?: Record<string, any>) => {
    mixPanelAnalytics?.track(eventName, properties);

    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        ...properties,
      },
    });
  };

  // Common auth tracking methods
  const trackAuthAttempt = (
    type: "login" | "sign_up",
    method: string,
    email: string,
    properties?: Record<string, any>
  ) => {
    trackEvent(`${type}_attempt`, {
      method,
      email,
      ...properties,
    });
  };

  const trackAuthSuccess = (
    type: "login" | "sign_up",
    method: string,
    email: string,
    properties?: Record<string, any>
  ) => {
    trackEvent(`${type}_success`, {
      method,
      email,
      ...properties,
    });
  };

  const trackAuthError = (
    type: "login" | "sign_up",
    method: string,
    email: string,
    error: string,
    properties?: Record<string, any>
  ) => {
    trackEvent(`${type}_error`, {
      method,
      email,
      error_message: error,
      ...properties,
    });
  };

  const pageView = (pageTitle: string, path: string) => {
    // Track page view with MixPnel
    mixPanelAnalytics?.track("Page View", {
      title: pageTitle,
      path: path,
    });

    // Track page view with GTM
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        page: {
          title: pageTitle,
          path: path,
        },
      },
    });
  };

  // Payment-related traking methods
  const trackPaymentFlow = (type: string, properties?: Record<string, any>) => {
    mixPanelAnalytics?.track("enter_payment_flow", {
      payment_type: type,
      ...properties,
    });

    ga4Context?.track("begin_checkout", {
      payment_type: type,
      ...properties,
    });

    TagManager.dataLayer({
      dataLayer: {
        event: "begin_checkout",
        payment_type: type,
        ...properties,
      },
    });
  };

  const trackPaymentError = (
    error: string,
    properties?: Record<string, any>
  ) => {
    trackEvent("payment_error", {
      error_message: error,
      ...properties,
    });
  };

  return {
    trackEvent,
    pageView,
    trackAuthAttempt,
    trackAuthSuccess,
    trackAuthError,
    trackPaymentFlow,
    trackPaymentError,
  };
};
