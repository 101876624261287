import { createContext } from "react";
import { Analytics } from "firebase/analytics";
import {
  ActiveEnvironmentConfig,
  EnvironmentConfig,
} from "../../config/Environment";
import { analytics } from "../../firebase";

export type Properties = {
  [key: string]: boolean | string | number;
};

export interface TruAnalytics {
  initialize(): void;
  track(eventName: string, attributes: Properties): void;
}

const loadMixPanel = () => import('mixpanel-browser');
const loadTagManager = () => import('react-gtm-module');

export class MixPanelAnalytics {
  environmentConfig: EnvironmentConfig;
  private mixpanel: any = null;

  constructor(_environmentConfig: EnvironmentConfig) {
    this.environmentConfig = _environmentConfig;
    this.initialize();
  }

  initialize = async () => {
    if (typeof window !== 'undefined') {
      const mixpanelModule = await loadMixPanel();
      this.mixpanel = mixpanelModule.default;
      this.mixpanel.init(this.environmentConfig.analytics.mixpanel_token, {
        debug: process.env.NODE_ENV === 'development',
        track_pageview: true,
        persistence: "localStorage",
      });
    }
  };

  track = async (eventName: string, attributes: Properties = {}) => {
    if (!this.mixpanel) {
      const mixpanelModule = await loadMixPanel();
      this.mixpanel = mixpanelModule.default;
    }
    this.mixpanel?.track(eventName, attributes);
  };
}

export class GA4Analytics implements TruAnalytics {
  environmentConfig: EnvironmentConfig;
  fireAnalytics: Analytics;
  private tagManager: any = null;

  constructor(targetEnvConfig: EnvironmentConfig) {
    this.environmentConfig = targetEnvConfig;
    this.fireAnalytics = analytics;
    this.initialize();
  }

  initialize = async () => {
    if (typeof window !== 'undefined') {
      const tagManagerModule = await loadTagManager();
      this.tagManager = tagManagerModule.default;

      const tagManagerArgs = {
        gtmId: this.environmentConfig?.analytics.gtmID as string,
      };

      this.tagManager.initialize(tagManagerArgs);
    }
  };

  track(eventName: string, attributes: Properties): void {
    //TODO: Fixme
    //this.fireAnalytics.logEvent(eventName);
  }
}

export const MixPanelAnalyticsContext = createContext(
  new MixPanelAnalytics(ActiveEnvironmentConfig())
);

export const GA4AnalyticsContext = createContext(
  new GA4Analytics(ActiveEnvironmentConfig())
);

export default MixPanelAnalytics;
