import { useEffect, useState } from "react";

interface EndorsementsMobile {
  id: number;
  title: string;
  endorsement: string;
  content: string;
  logoSrc: string;
}
interface EndorsementsDesktop {
  id: number;
  logoSrc: string;
}
interface EndorsementsProps {
  EndorsementsMobile?: EndorsementsMobile[];
  EndorsementsDesktop?: EndorsementsDesktop[];
  backgroundColor?: string; // Must be a Tailwind class name
}

const DefaultEndorsementsMobile = [
  {
    id: 1,
    title: "Tina Griffin",
    endorsement: "The Counter Culture Mom",
    content:
      "Are you tired of your kids playing violent video games, seeing graphic content online and delving into questionable stuff in the world of screens? Are you looking for Biblical content that would teach your children values? Well look no further! I'm so thankful TruPlay exists, you are the solution to the problems I've been addressing around this globe forever",
    logoSrc: require("../../../global-assets/assets/end_logo1.webp"),
  },
  {
    id: 2,
    title: "",
    endorsement: "",
    content:
      "TruPlay represents the first and biggest NFT release from a Christian tech company... selling out within 10 minutes of their release",
    logoSrc: require("../../../global-assets/assets/end_logo2.webp"),
  },

  {
    id: 3,
    title: "Will Graham",
    endorsement:
      "Executive Vice President and Evangelist, Billy Graham Evangelical Association",
    content:
      "The quality of TruPlay's content is unparalleled, and it's guaranteed not to expose your children to questionable content or ads of any kind. Rather, the games will encourage and grow your child's faith.",
    logoSrc: "",
  },
  {
    id: 4,
    title: "",
    endorsement: "",
    content: "TruPlay: An embraceable gaming alternative",
    logoSrc: require("../../../global-assets/assets/end_logo4.webp"),
  },
  {
    id: 5,
    title: "Pastor Jack Hayford",
    endorsement: "Founder, The Kings University Seminary",
    content:
      "About our upcoming Journey of Jesus game in development: 'Journey of Jesus is marvelous because its much more than a game.'",
    logoSrc: "",
  },
  {
    id: 6,
    title: "Dr. Darrell Bock",
    endorsement: "Dallas Theological Seminary",
    content:
      "About our upcoming Journey of Jesus game in development: 'Journey of Jesus takes players closer to the life of Christ in a fun, reflective, and entertaining way.'",
    logoSrc: "",
  },
  {
    id: 7,
    title: "Candace Cameron Bure",
    endorsement: "Candace Cameron Bure : The Podcast",
    content:
      "I'm excited to tell you about TruPlay Games, a brand new faith based app for kids that includes animated shorts, digital comic books and a bunch of different games all made to invite children to experience God's truth. With TruPlay kids have fun in a world of safe and original content",
    logoSrc: require("../../../global-assets/assets/end_logo7.webp"),
  },

  {
    id: 8,
    title: "Ken Harrison",
    endorsement: "CEO, Promise Keepers",
    content:
      "In a world that actively preys on the vulnerable hearts and minds of our kids, we must be intentional in providing them with high-quality, faith-formative entertainment. TruPlay understands that those who obtain a solid foundation of faith from an early age are more likely to become God-fearing fathers and mothers, husbands, and wives. I'm grateful to TruPlay for making a bold choice to insert Christ into the gaming space and support the cultivation of healthy families in the future.",
    logoSrc: require("../../../global-assets/assets/end_logo8.webp"),
  },
  {
    id: 9,
    title: "Sam Rodriguez",
    endorsement:
      "President of the National Hispanic Christian Leadership Conference",
    content:
      "About our game Stained Glass: 'Of course I endorse it and of course it shines the light of Christ, but it has the power of engaging gamers and the next generation with content that is transformative. Not only something that will entertain, but something that will inspire, inform and impart the truth of the reality of Jesus Christ as Lord. Tell your kids about it, because this can change their lives.'",
    logoSrc: "",
  },
];

const DefaultEndorsementsDesktop = [
  {
    id: 1,
    logoSrc: require("../../../global-assets/assets/end_logo1.webp"),
  },
  {
    id: 2,
    logoSrc: require("../../../global-assets/assets/PoM.webp"),
  },

  {
    id: 3,
    logoSrc: require("../../../global-assets/assets/end_logo2.webp"),
  },

  {
    id: 4,
    logoSrc: require("../../../global-assets/assets/CHARISMA.webp"),
  },

  {
    id: 5,
    logoSrc: require("../../../global-assets/assets/layer1.webp"),
  },
  {
    id: 6,
    logoSrc: require("../../../global-assets/assets/Group.webp"),
  },
];

export default function Endorsements({
  EndorsementsDesktop = DefaultEndorsementsDesktop,
  EndorsementsMobile = DefaultEndorsementsMobile,
  backgroundColor = "bg-none shadow-none",
}: EndorsementsProps) {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [intervalActive] = useState<boolean>(true);
  const [slideOutAnimation] = useState<boolean>(false);

  useEffect(() => {
    if (intervalActive) {
      let interval1 = setInterval(() => {
        const newIndex = (activeIndex + 1) % EndorsementsMobile.length;
        setActiveIndex(newIndex);
      }, 7000);
      return () => clearInterval(interval1);
    }
  }, [EndorsementsMobile.length, activeIndex, intervalActive]);

  const activeSlide = EndorsementsMobile[activeIndex];

  return (
    <div
      className={
        "relative z-[3] mx-auto flex w-full flex-col items-center justify-center gap-8 rounded-[30px] bg-none px-7 py-10 shadow-[0_-15px_80px_rgba(0,0,0,0.5)] lg:rounded-[40px] lg:p-12" +
        (backgroundColor ? " " + backgroundColor : "")
      }
    >
      <h2 className="text-transparent mx-auto flex w-full max-w-[820px] justify-center bg-gradient_text bg-clip-text">
        TruPlay is Endorsed by:
      </h2>
      <div className="!hidden w-full flex-1 flex-row items-center gap-16 lg:!flex">
        {EndorsementsDesktop.map((item, index) => (
          <div
            className="mx-auto flex max-w-none flex-1 items-center justify-center text-center"
            key={index}
          >
            <img loading="eager" alt="" src={item.logoSrc} />
          </div>
        ))}
      </div>
      <div
        key={activeSlide.id - 1}
        className={`tru-slide-in-anim relative inline-block h-full w-full whitespace-normal text-left align-top lg:!hidden ${
          slideOutAnimation ? "tru-slide-out-anim" : ""
        }`}
      >
        <div className="flex w-full flex-col justify-start gap-5 text-center">
          {activeSlide.logoSrc.length ? (
            <div className="mx-auto flex max-w-[250px] flex-1 items-center justify-center text-center sm:max-w-[300px] md:max-w-[400px]">
              <img loading="eager" alt="" src={activeSlide.logoSrc} />
            </div>
          ) : null}
          <p className="m-0 flex flex-col items-center justify-start text-white">
            {activeSlide.content}
          </p>
          <div className="text-turquoise-500">
            <div className="text-lg font-bold">{activeSlide.title}</div>
            <div className="text-base font-normal">
              {activeSlide.endorsement}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
