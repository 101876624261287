import { useEffect, useState } from "react";
import { postService } from "../../config/PostService";
import { News } from "../../lib/News";
import PageHeaderButton from "../../components/common/PageHeaderButton";
import Button from "../../components/common/Button";
import SEO from "../../components/common/SEO";

// Images
import newsbg from "../../global-assets/assets/newsbg.webp";

export default function NewsPage() {
  const [news, setNews] = useState<News[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const itemsPerPage = 10;

  const fetchNews = async (page: number) => {
    try {
      setLoadingMore(page > 1);
      const { news: newItems, total } = await postService.getAllNews(page, itemsPerPage);
      
      if (page === 1) {
        setNews(newItems);
      } else {
        setNews(prevNews => [...prevNews, ...newItems]);
      }
      
      // Check if we've loaded all available news items
      setHasMore(news.length + newItems.length < total);
    } catch (err) {
      setError("Failed to load news articles");
      console.error("Error fetching news:", err);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    fetchNews(1);
  }, []);

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    fetchNews(nextPage);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="mx-auto">
      <SEO 
        title="TruPlay News - Latest Updates and Announcements"
        description="Stay up to date with the latest news, updates, and announcements from TruPlay Games."
        canonicalUrl="https://truplaygames.com/news"
        ogTitle="TruPlay News and Updates"
        ogDescription="Get the latest news and updates from TruPlay Games - the platform for Christian games and content."
      />
      <PageHeaderButton
        backgroundImage={newsbg}
        title="News"
        isActive={true}
      />
      <div className="mx-auto mt-10 grid max-w-7xl gap-6 px-4 md:grid-cols-2 lg:grid-cols-3">
        {news.map((newsItem) => (
          <a
            key={newsItem._id}
            href={newsItem.url}
            target="_blank"
            rel="noopener noreferrer"
            className="group block overflow-hidden rounded-lg bg-white shadow-md transition-transform hover:-translate-y-1 hover:shadow-lg"
          >
            {newsItem.mainImage && (
              <div className="relative h-48 overflow-hidden">
                <img
                  src={newsItem.mainImage.asset.url}
                  alt={newsItem.title}
                  className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-105"
                />
              </div>
            )}
            <div className="p-4">
              <h2 className="mb-2 text-xl font-semibold text-gray-900">
                {newsItem.title}
              </h2>
              <div className="flex items-center gap-2 text-sm text-gray-600">
                <span>{new Date(newsItem.publishedAt).toLocaleDateString()}</span>
                <span className="ml-auto text-cerulean-600">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                  </svg>
                </span>
              </div>
            </div>
          </a>
        ))}
      </div>
      
      {hasMore && (
        <div className="flex justify-center mt-8 mb-12">
          <Button
            onClick={handleLoadMore}
            disabled={loadingMore}
            btnType="green"
            className="px-6 py-2 bg-cerulean-600 text-white rounded-md hover:bg-cerulean-700 transition-colors disabled:bg-gray-400"
          >
            {loadingMore ? "Loading..." : "Load More"}
          </Button>
        </div>
      )}
    </div>
  );
}
