import { client } from '../sanityClient';
import { Post } from '../lib/Post';
import { News } from '../lib/News';

export const postService = {
    getAllPosts: async (page = 1, limit = 10): Promise<{ posts: Post[], total: number }> => {
        const start = (page - 1) * limit;
        const query = `{
            "posts": *[_type == "post"] | order(publishedAt desc) [${start}...${start + limit}] {
                _id,
                title,
                slug,
                metaDescription,
                mainImage {
                    asset-> {
                        url
                    }
                },
                author->{
                    _id,
                    name,
                    image {
                        asset-> {
                            url
                        }
                    }
                },
                categories[]->{
                    _id,
                    title
                },
                publishedAt,
                body
            },
            "total": count(*[_type == "post"])
        }`;

        return await client.fetch(query);
    },

    getPostBySlug: async (slug: string): Promise<Post> => {
        const query = `*[_type == "post" && slug.current == $slug][0]{
            _id,
            title,
            slug,
            metaDescription,
            mainImage {
                asset-> {
                    url
                }
            },
            author->{
                _id,
                name,
                image {
                    asset-> {
                        url
                    }
                }
            },
            categories[]->{
                _id,
                title
            },
            publishedAt,
            body
        }`;

        return await client.fetch(query, { slug });
    },
    
    getAllNews: async (page = 1, limit = 10): Promise<{ news: News[], total: number }> => {
        const start = (page - 1) * limit;
        const query = `{
            "news": *[_type == "news"] | order(publishedAt desc) [${start}...${start + limit}] {
                _id,
                title,
                mainImage {
                    asset-> {
                        url
                    }
                },
                url,
                publishedAt
            },
            "total": count(*[_type == "news"])
        }`;

        return await client.fetch(query);
    }
};