import React from "react";
import { Link } from "react-router-dom";
import TruPlayLogo from "../../../global-assets/assets/truplay-logo-large.webp";
import { GetOrCreateSessionAuthContext } from "../../../config/UserAuthContext";
import Cookies from "js-cookie";
import TagManager from "react-gtm-module";
import Button from "../../common/Button";

interface PromiseKeepersFooterProps {
  handleScroll: () => void;
}

const PromiseKeepersFooter = ({ handleScroll }: PromiseKeepersFooterProps) => {
  const osType = navigator.userAgent;
  const userContext = GetOrCreateSessionAuthContext();
  const GlobalUserId = Cookies.get("GlobalUserId");

  const handleGtagClick =
    (linkURL: string) =>
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      const linkName = e.currentTarget.innerText;

      TagManager.dataLayer({
        dataLayer: {
          event: "select_content",
          content_type: "menu",
          os_type: osType,
          link_name: linkName,
          link_url: linkURL,
          plan_type: userContext.subscription.sources[0]?.productId ?? "none",
          custom_user_id: GlobalUserId || undefined,
        },
      });
    };

  return (
    <div className="relative overflow-hidden bg-[#001b36] px-[20px] pb-[52px] pt-[60px] md:px-[30px] lg:px-[50px]">
      <div className="mx-auto flex max-w-[1440px] flex-col items-center gap-[2.5rem] md:flex-row md:justify-between">
        <div className="flex flex-col items-center text-center md:items-start md:text-left">
          <div className="mb-[10px] max-w-[150px] md:max-w-[193px]">
            <img src={TruPlayLogo} loading="lazy" alt="" />
          </div>
          <div className="mb-[20px] font-display text-[13px] font-medium leading-[1.6] text-white md:mb-[30px]">
            <div>
              © TruPlay. All Rights Reserved.
              <br />
              <Link
                id="privacy_policy_link"
                to="/privacy-policy"
                className="cursor-pointer underline"
                onClick={(e) => handleGtagClick("/privacy-policy")}
              >
                Privacy Policy
              </Link>
              .{" "}
              <Link
                id="terms_and_conditions_link"
                to="/terms-and-conditions"
                className="cursor-pointer underline"
                onClick={(e) => handleGtagClick("/terms-and-conditions")}
              >
                Terms &amp; Conditions
              </Link>
              .
            </div>
          </div>
          <div className="font-display text-[11px] font-medium text-white">
            Content and platform availability may vary by region.
          </div>
        </div>
        <div className="self-center">
          <Button
            id="pk_footer_join_the_mission"
            onClick={handleScroll}
            btnType="green"
          >
            Join the Mission
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PromiseKeepersFooter;
