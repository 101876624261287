/* eslint-disable react/jsx-pascal-case */
import { useEffect } from "react";
import GamesMoreHero from "../../components/games-and-more/GamesMoreHero/GamesMoreHero";
import GamesMoreBenjamin from "../../components/games-and-more/GamesMoreBenjamin/GamesMoreBenjamin";
import GamesMoreActionBible from "../../components/games-and-more/GamesMoreActionBible/GamesMoreActionBible";
import GamesMoreComics from "../../components/games-and-more/GamesMoreComics/GamesMoreComics";
import GamesMoreMain from "../../components/games-and-more/GamesMoreMain/GamesMoreMain";
import GamesMoreRhymverse from "../../components/games-and-more/GamesMoreRhymverse/GamesMoreRhymverse";
import SEO from "../../components/common/SEO";

const GamesAndMore = () => {
  useEffect(() => {
    const cookiesAvailable = () => {
      try {
        localStorage.setItem("test", "test");
        localStorage.removeItem("test");
        return true;
      } catch (e) {
        return false;
      }
    };

    if (cookiesAvailable() && process.env.REACT_APP_ENVIRONMENT === "prod") {
      const script = document.createElement("script");
      script.src =
        "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=STmu7s";
      script.async = true;
      script.type = "text/javascript";
      document.body.appendChild(script);

      return () => {
        if (document.body.contains(script)) {
          document.body.removeChild(script);
        }
      };
    }
  }, []);

  return (
    <div id="test_data_gamesAndMore_page">
      <SEO
        title="Games | Explore Our Game Library | Truplay Games"
        description="Browse our collection of fun and educational games for kids of all ages. New games are added regularly! Play a variety of safe Christian games, bible stories, and family-friendly videos all in one place."
        canonicalUrl="https://www.truplaygames.com/games"
      />
      <div className="relative overflow-hidden">
        <div className="tru-game-list-top-bg absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-50" />
        <div className="tru-game-list-blue-bg absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-20" />
        <div className="tru-game-list-purple-bg absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-50" />
        <GamesMoreHero />
        <GamesMoreMain />
      </div>
      <GamesMoreComics />
      <GamesMoreActionBible />
      <GamesMoreRhymverse />
      <GamesMoreBenjamin />
    </div>
  );
};

export default GamesAndMore;
