import { useCallback, useEffect, useRef, useState } from "react";
import { navigateTo } from "../../../config/UserAuthContext";
import { GetContinueToQueryString } from "../../../lib/PassedParams";
import {
  GetEmailAddress,
  isAuthenticatedWithValidToken,
} from "../../../config/UserAuthContext";

// Components
import UserMenuLight from "../UserMenu/UserMenuLight";
import UserMenu from "../UserMenu/UserMenu";
import Button from "../../common/Button";

// Assets
import TruPlayLogoDark from "../../../global-assets/assets/truplay-logo-dark.svg";
import TruPlayLogo from "../../../global-assets/assets/truplay-logo-large.webp";
import UserIcon from "../../../global-assets/assets/User_Icon.svg";

interface RegNavUnauthProps {
  showMobileLogo?: boolean;
  showUserMenu?: boolean;
}

/**
 *
 * @param setAuth
 * @constructor
 */
export default function RegNavUnauth({
  showMobileLogo = true,
  showUserMenu = true,
}: RegNavUnauthProps) {
  let isAuthenticated = isAuthenticatedWithValidToken();
  const emailAddress: string = GetEmailAddress() || "";

  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
  const [isUserLightMenuOpen, setIsUserLightMenuOpen] =
    useState<boolean>(false);

  // Ref for User Icon circle, used to anchor the menu at large screen widths
  const userIconRef = useRef<HTMLInputElement>(null);
  // User Icon circle element's positions, used to determine menu width
  const userIconPositions = useRef<DOMRect | undefined>();
  // Updates the User Icon circle position as the screen width changes
  const updateUserIconPos = useCallback(() => {
    userIconPositions.current = userIconRef.current?.getBoundingClientRect();
  }, []);

  useEffect(() => {
    if (userIconPositions.current === undefined) {
      userIconPositions.current = userIconRef.current?.getBoundingClientRect();
    }
    window.addEventListener("resize", updateUserIconPos);
    return () => window.removeEventListener("resize", updateUserIconPos);
  }, [updateUserIconPos]);

  // Ref for the User Menu, used to check if the user is clicking within or outside it
  const userMenuRef = useRef<HTMLDivElement>(null);
  const closeUserMenu = useCallback(
    (e: any) => {
      if (isUserMenuOpen && !userMenuRef.current?.contains(e.target)) {
        setIsUserMenuOpen(false);
      }
    },
    [isUserMenuOpen]
  );

  useEffect(() => {
    window.addEventListener("mousedown", closeUserMenu);
    return () => window.removeEventListener("mousedown", closeUserMenu);
  }, [closeUserMenu]);

  const userLightMenuRef = useRef<HTMLDivElement>(null);
  const closeUserLightMenu = useCallback(
    (e: any) => {
      if (
        isUserLightMenuOpen &&
        !userLightMenuRef.current?.contains(e.target)
      ) {
        setIsUserLightMenuOpen(false);
      }
    },
    [isUserLightMenuOpen]
  );

  useEffect(() => {
    window.addEventListener("mousedown", closeUserLightMenu);
    return () => window.removeEventListener("mousedown", closeUserLightMenu);
  }, [closeUserLightMenu]);

  return (
    <>
      <link href="https://fonts.googleapis.com" rel="preconnect" />
      <link
        href="https://fonts.gstatic.com"
        rel="preconnect"
        crossOrigin="anonymous"
      />
      <div
        className={
          "fixed bottom-auto left-0 right-0 top-0 z-[100] h-[72px] w-full" +
          " bg-white sm:h-[104px] regmd:bg-[unset] regmd:bg-global_nav_bg md:h-[114px]"
        }
      >
        <div className="mx-auto max-w-[1536px]">
          {isAuthenticated && (
            <>
              <div className="hidden regmd:!block">
                <UserMenu
                  isOpen={isUserMenuOpen}
                  setIsOpen={setIsUserMenuOpen}
                  userEmail={emailAddress}
                  userIconRightCoord={{
                    right: userIconPositions.current?.right,
                  }}
                  menuRef={userMenuRef}
                />
              </div>
              <div className="regmd:hidden">
                <UserMenuLight
                  isOpen={isUserLightMenuOpen}
                  setIsOpen={setIsUserLightMenuOpen}
                  userEmail={emailAddress}
                  userIconRightCoord={{
                    right: userIconPositions.current?.right,
                  }}
                  menuRef={userLightMenuRef}
                />
              </div>
            </>
          )}
          <div className="relative flex h-[72px] items-center justify-between px-6 py-4 sm:h-[104px] md:h-[114px]">
            <div
              className={
                "flex items-center" +
                (!showMobileLogo ? " invisible !mb-0 regmd:!visible" : "")
              }
            >
              <div className="cursor-pointer" onClick={() => navigateTo("/")}>
                <img
                  src={TruPlayLogoDark}
                  alt="Truplay-logo"
                  className={
                    "w-[7.5rem] sm:w-[9.5rem] regmd:!hidden lg:w-48" +
                    (isUserLightMenuOpen ? " hidden" : "")
                  }
                  id="unauth_tru_logo"
                />
                <img
                  src={TruPlayLogo}
                  alt="Truplay-logo"
                  className={
                    "hidden w-[7.5rem] sm:w-[9.5rem] regmd:inline lg:w-48" +
                    (isUserMenuOpen ? " !hidden md:!inline" : "")
                  }
                  id="unauth_tru_logo"
                />
              </div>
            </div>
            {!isAuthenticated && (
              <div className="flex items-center gap-2 sm:gap-4">
                <Button
                  id="log_in_btn"
                  btnType="tertiaryAlt"
                  className="!text-xl regmd:hidden"
                  onClick={() => {
                    navigateTo(`/login${GetContinueToQueryString()}`);
                  }}
                >
                  Log In
                </Button>
                <Button
                  id="log_in_btn"
                  className="!hidden !px-10 regmd:!inline"
                  onClick={() => {
                    navigateTo(`/login${GetContinueToQueryString()}`);
                  }}
                  btnType="secondary"
                >
                  Log In
                </Button>
              </div>
            )}
            {isAuthenticated && (
              <div
                id="active_user_menu_option"
                className={
                  "relative flex min-h-12 min-w-12 cursor-pointer items-center justify-center rounded-full" +
                  " bg-user_menu_icon duration-200 hover:scale-110 hover:shadow-[0_3px_20px_rgba(56,166,213,0.6)]" +
                  (!showUserMenu ? " hidden" : "")
                }
                onClick={() => {
                  setIsUserMenuOpen(true);
                  setIsUserLightMenuOpen(true);
                }}
                ref={userIconRef}
              >
                <img
                  src={UserIcon}
                  alt="User Icon"
                  className={
                    "h-[1.1rem]" +
                    (isUserMenuOpen || isUserLightMenuOpen
                      ? " hidden"
                      : " block")
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
