import { useState, useEffect } from "react";

// Packages
import Cookies from "js-cookie";

// Context
import {
  Context,
  GetOrCreateSessionAuthContext,
} from "../../config/UserAuthContext";
import { ErrorCodeMap } from "../../lib/Constants";
import { GetContinueToQueryString } from "../../lib/PassedParams";

// Components
import Login from "./Login";
import Button from "../../components/common/Button";
import SEO from "../../components/common/SEO";

// Assets
import GoogleLogo from "../RegistrationFlow/assets/logo-google.svg";

// Firebase
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../firebase";
import { useRemoteConfig } from "../../config/FBRemoteConfigContext";
import { Link, useNavigate } from "react-router-dom";
import { InlineErrorMessage } from "../../components/notification/InlineErrorMessage/InlineErrorMessage";
import { PulseLoader } from "react-spinners";
import TopNavbar from "../../components/nav/TopNavbar/TopNavbar";
import { useAnalytics } from "../../hooks/useAnalytics";
import TagManager from "react-gtm-module";

/**
 * Login Page to authenticate users with valid email address on file
 *
 * @param props
 * @constructor
 */
interface LoginProps {
  setAuth: any;
  setLoginData: any;
}

export default function SSOLogin({ setAuth, setLoginData }: LoginProps) {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const firebaseFlags = useRemoteConfig();
  const provider = new GoogleAuthProvider();
  const [isEmailLogIn, setIsEmailLogIn] = useState<boolean>(false);
  const [isCallingAPI, setIsCallingAPI] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string | null>(null);
  const [flagsLoaded, setFlagsLoaded] = useState(false);

  const osType = navigator.userAgent;
  const GlobalUserId = Cookies.get("GlobalUserId");
  const userContext = GetOrCreateSessionAuthContext();
  const subscriptionInfo = userContext.subscription.sources;

  useEffect(() => {
    if (Object.keys(firebaseFlags).length > 0) {
      setFlagsLoaded(true);
    }
  }, [firebaseFlags]);

  const handleGoogleSignUp = () => {
    setIsCallingAPI(true);

    analytics.trackAuthAttempt("login", "google", "", {
      os_type: osType,
      plan_type: subscriptionInfo[0] || "none",
      custom_user_id: GlobalUserId || undefined,
    });

    signInWithPopup(auth, provider)
      .then((result) => {
        analytics.trackAuthSuccess("login", "google", "", {
          os_type: osType,
          plan_type: subscriptionInfo[0] || "none",
          custom_user_id: GlobalUserId || undefined,
        });

        // The signed-in user info.
        const user = result.user;
        user
          .getIdToken()
          .then((accessToken) => {
            Cookies.set(
              Context.AuthContext,
              JSON.stringify({ userID: user.uid, token: accessToken })
            );
            const userData: any = {
              communicationsOptIn: false,
              subscription: {
                isActive: false,
                expireTime: null,
                sources: Array(0),
              },
              token: accessToken,
              uuId: user.uid,
              verifyEmail: {
                isVerified: user.emailVerified,
                didSendNewVerificationEmail: false,
                shouldShowEmailVerificationScreen: false,
              },
              sso: true,
            };

            Cookies.set(Context.UserContext, JSON.stringify(userData));
            Cookies.set("EmailAddress", user.email || "");

            analytics.trackEvent("login", {
              method: "google",
              os_type: osType,
              plan_type: subscriptionInfo[0] || "none",
              custom_user_id: GlobalUserId || undefined,
            });

            TagManager.dataLayer({
              dataLayer: {
                event: "login",
                method: "google",
                plan_type: subscriptionInfo[0] || "none",
                os_type: osType,
                custom_user_id: GlobalUserId || undefined,
              },
            });
          })
          .catch((error) => {
            const errorMessage = ErrorCodeMap[error.code] || error.message;
            analytics.trackAuthError("login", "google", "", errorMessage, {
              os_type: osType,
              plan_type: subscriptionInfo[0] || "none",
              custom_user_id: GlobalUserId || undefined,
            });

            setLoginError(errorMessage);
            setIsCallingAPI(false);
          });
        setLoginData(user);
        setAuth(true);
        navigate("/pricing");
        setIsCallingAPI(false);
      })
      .catch((error) => {
        const errorMessage = ErrorCodeMap[error.code] || error.message;

        analytics.trackAuthError("login", "google", "", errorMessage, {
          os_type: osType,
          plan_type: subscriptionInfo[0] || "none",
          custom_user_id: GlobalUserId || undefined,
        });

        setLoginError(errorMessage);
        setIsCallingAPI(false);
      });
  };

  if (!flagsLoaded || isCallingAPI) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-white regmd:bg-indigo-900">
        <div className="hidden regmd:block">
          <PulseLoader color="#fff" />
        </div>
        <div className="regmd:hidden">
          <PulseLoader color="#003E7A" />
        </div>
      </div>
    );
  }

  if (!firebaseFlags.firebaseSSO) {
    return <Login setAuth={setAuth} setLoginData={setLoginData} />;
  }

  if (isEmailLogIn) {
    return (
      <Login
        setAuth={setAuth}
        setLoginData={setLoginData}
        setIsEmailLogIn={setIsEmailLogIn}
      />
    );
  }

  return (
    <>
      <div className="hidden regmd:block">
        <TopNavbar />
      </div>
      <div className="regmd:hidden">
        <TopNavbar navType="light" />
      </div>
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] min-h-screen bg-white regmd:hidden" />
      <div className="tru-single-modal-bg absolute bottom-0 left-0 right-0 top-0 -z-[1] hidden opacity-60 regmd:block" />
      <div
        id="test_data_login_page"
        className="h-full min-h-[calc(100vh-272px)] regmd:flex regmd:items-center regmd:justify-center regmd:py-44"
      >
        <SEO
          title="TruPlay Games: Christian Games, Videos, and Stories"
          description="Login to your TruPlay account to access faith-based content and more!"
          canonicalUrl="https://accounts.truplaygames.com/login"
        />
        <div
          className={
            "relative mx-auto flex min-h-screen flex-col gap-4 px-6 pb-0 pt-32 regmd:min-h-[unset]" +
            " regmd:w-[600px] regmd:gap-6 regmd:rounded-[2rem] regmd:bg-white regmd:p-12"
          }
        >
          <h4 className="text-center text-3xl text-indigo-500">
            Welcome Back!
          </h4>
          <p className="mb-0 text-center font-normal text-cool-gray-900">
            Please log in below.
          </p>
          <div className="flex w-full flex-col gap-4 px-6 pb-8 pt-4 regmd:static regmd:p-0">
            <Button
              id="log_in_google_btn"
              onClick={handleGoogleSignUp}
              btnType="googleSSO"
              className="w-full"
              leftIcon={
                <img
                  src={GoogleLogo}
                  alt="Google"
                  className="h-7 w-7 regmd:h-8 regmd:w-8"
                />
              }
            >
              Continue With Google
            </Button>
            <Button
              id="log_in_email_pass_btn"
              onClick={() => setIsEmailLogIn(true)}
              btnType="secondaryAlt"
              className="w-full regmd:h-14"
            >
              Log In With Email
            </Button>
            <div className="flex items-center justify-center text-center">
              <span className="inline-block p-0.5 text-base font-medium text-cool-gray-900">
                Don't have an account?
              </span>
              <Link to={"/signup" + GetContinueToQueryString()}>
                <Button
                  id="log_in_sign_up_cta"
                  btnType="tertiaryAlt"
                  className="ml-2 !text-base hover:underline active:!text-cerulean-500"
                >
                  Sign Up
                </Button>
              </Link>
            </div>
            {loginError && <InlineErrorMessage errorMessage={loginError} />}
          </div>
          <div className="text-center sm:hidden">
            <a
              href="https://www.kidsafeseal.com/certifiedproducts/truplay_app.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                style={{ border: 0 }}
                width="150"
                alt="TruPlay: Play Christian Games app is a member of the kidSAFE Seal Program."
                src="https://www.kidsafeseal.com/sealimage/14385588753829552/truplay_app_svg_whitetm.svg"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
