import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { postService } from "../../config/PostService";
import { Post } from "../../lib/Post";
import BlogHeader from "./BlogHeader";
import { PortableText } from "@portabletext/react";
import SEO from "../common/SEO";

export default function BlogPost() {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<Post | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPost = async () => {
      if (!slug) return;
      try {
        const post = await postService.getPostBySlug(slug);
        setPost(post);
        console.log(post);
      } catch (err) {
        setError("Failed to load blog post");
        console.error("Error fetching post:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!post) return <div>Post not found</div>;

  return (
    <div className="flex min-h-screen flex-col">
      {post && (
        <SEO
          title={post.title}
          description={post.metaDescription || ""}
          ogType="article"
          ogTitle={post.title}
          ogDescription={post.metaDescription || ""}
          canonicalUrl={`https://truplaygames.com/blog/${post.slug.current || post.slug}`}
          ogImage={post.mainImage?.asset.url}
          twitterImage={post.mainImage?.asset.url}
        />
      )}

      <BlogHeader backgroundImage={post.mainImage?.asset.url} />

      {/* Blog Content Container */}
      <div className="mx-auto w-full max-w-4xl px-4">
        {/* Title and Metadata Section */}
        <div className="relative z-10 -mt-20 rounded-lg bg-white p-8 shadow-lg">
          <h1 className="mb-6 text-4xl font-bold gradient-text-main md:text-5xl lg:text-6xl">
            {post.title}
          </h1>

          <div className="mb-6 flex items-center gap-4 text-gray-600">
            {post.author && (
              <div className="flex items-center gap-2">
                {post.author.image && (
                  <img
                    src={post.author.image.asset.url}
                    alt={post.author.name}
                    className="h-8 w-8 rounded-full"
                  />
                )}
                <span>{post.author.name}</span>
              </div>
            )}
            <span>•</span>
            <span>{new Date(post.publishedAt).toLocaleDateString()}</span>
          </div>

          {post.categories && post.categories.length > 0 && (
            <div className="mb-8 flex gap-2">
              {post.categories.map((category) => (
                <span
                  key={category._id}
                  className="rounded bg-cerulean-500/20 px-3 py-1 text-sm text-cerulean-600"
                >
                  {category.title}
                </span>
              ))}
            </div>
          )}

          {/* Blog Content */}
          <div className="prose prose-lg max-w-none">
            <PortableText value={post.body} />
          </div>
        </div>
      </div>
    </div>
  );
}
