import TruPlayLogo from "../../../global-assets/assets/truplay-logo-large.webp";
import { navigateTo } from "../../../config/UserAuthContext";

const TruPlayAppsNav = () => {
  return (
    <>
      <link href="https://fonts.googleapis.com" rel="preconnect" />
      <link
        href="https://fonts.gstatic.com"
        rel="preconnect"
        crossOrigin="anonymous"
      />
      <div>
        <div className="relative flex h-[104px] items-center justify-between p-6 md:h-[114px]">
          <div className="flex items-center">
            <div className="cursor-pointer" onClick={() => navigateTo("/")}>
              <img
                src={TruPlayLogo}
                alt="Truplay-logo"
                className="w-[7.5rem] sm:w-[9.5rem] lg:w-48"
                id="unauth_tru_logo"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TruPlayAppsNav;
