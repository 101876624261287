interface BlogHeaderProps {
  backgroundImage?: string;
}

const BlogHeader = ({ backgroundImage }: BlogHeaderProps) => {
  const headerStyle = {
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div className="relative h-[65vh] w-full" style={headerStyle}>
      <div className="absolute bottom-0 left-0 right-0 top-0 bg-black/35" />
    </div>
  );
};

export default BlogHeader;
