import { useState, useEffect } from "react";
import "./ActionBibleSlider.css";
import faq_arrow from "../../../global-assets/assets/faq_arrow.svg";

// Images //
import ActionBibleSlide1 from "../../../global-assets/assets/action-bible-slide1.webp";
import ActionBibleSlide2 from "../../../global-assets/assets/action-bible-slide2.webp";
import ActionBibleSlide3 from "../../../global-assets/assets/action-bible-slide3.webp";
import ActionBibleSlide4 from "../../../global-assets/assets/action-bible-slide4.webp";

const sliderImages = [
  ActionBibleSlide1,
  ActionBibleSlide2,
  ActionBibleSlide3,
  ActionBibleSlide4,
];

export default function ActionBibleSlider() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [intervalActive, setIntervalActive] = useState(true);
  const [slideOutAnimation, setSlideOutAnimation] = useState(false);

  useEffect(() => {
    if (intervalActive) {
      const interval = setInterval(() => {
        setActiveIndex((current) => (current + 1) % sliderImages.length);
      }, 7000);

      return () => clearInterval(interval);
    }
  }, [intervalActive]);

  /**
   * Delay the interval after a user action
   */
  const pauseInterval = () => {
    setIntervalActive(false);
    setTimeout(() => {
      setIntervalActive(true);
    }, 5000);
  };

  const handlePrevButtonClick = () => {
    setActiveIndex(
      (current) => (current - 1 + sliderImages.length) % sliderImages.length
    );
    setSlideOutAnimation(false);
    pauseInterval();
  };

  const handleNextButtonClick = () => {
    setActiveIndex((current) => (current + 1) % sliderImages.length);
    setSlideOutAnimation(true);
    pauseInterval();
  };

  const handleIndexButtonClick = (slideIndex: number) => {
    setActiveIndex(slideIndex);
    pauseInterval();
  };

  const slider = "relative clear-both h-[300px] text-center";
  const ABSlider = "z-[1] h-[550px] md:h-[700px] lg:h-[890px]";
  const slide = "relative inline-block h-full w-full";
  const sliderArrow =
    "absolute top-[45%] h-8 w-8 m-auto cursor-pointer md:w-12 md:h-12";
  const leftArrow = "left-0 rotate-90 sm:-left-5";
  const rightArrow = "right-0 -rotate-90 sm:-right-5";
  const sliderNavBtns =
    "absolute bottom-0 z-[2] m-auto flex h-10 md:h-24 md:h-32 w-full flex-row items-center justify-center text-center";
  const sliderNavBtnCircle =
    "relative mx-1 mb-2 inline-block h-5 w-5 cursor-pointer rounded-full border-0 bg-black opacity-60";
  const sliderNavBtnCircleActive =
    "bg-white drop-shadow-[0_0_16px_#38a6d5] opacity-100";

  const activeSlide = sliderImages[activeIndex];

  return (
    <div className={slider + " " + ABSlider}>
      <div
        key={activeIndex}
        className={
          slide +
          (slideOutAnimation
            ? " tru-ab-slide-out-anim"
            : " tru-ab-slide-in-anim")
        }
        style={{
          backgroundImage: `url(${activeSlide})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div
        className={sliderArrow + " " + leftArrow}
        onClick={handlePrevButtonClick}
      >
        <img src={faq_arrow} alt="Previous" />
      </div>
      <div
        className={sliderArrow + " " + rightArrow}
        onClick={handleNextButtonClick}
      >
        <img src={faq_arrow} alt="Next" />
      </div>
      <div className={sliderNavBtns}>
        {sliderImages.map((_, index) => (
          <button
            key={index}
            onClick={() => handleIndexButtonClick(index)}
            className={
              sliderNavBtnCircle +
              (activeIndex === index ? " " + sliderNavBtnCircleActive : "")
            }
          />
        ))}
      </div>
    </div>
  );
}
