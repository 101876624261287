import { createClient } from "@sanity/client";
import imageUrlBuilder from '@sanity/image-url'

export const client = createClient({
    projectId: "kkecty9e",
    dataset: "production",
    apiVersion: "2024-01-01",
    useCdn: false,
}) as any; // type assertion to get around typing issue

// image URL builder
const builder = imageUrlBuilder(client)

// Helper to generate image urls
export function urlFor(source: any) {
    return builder.image(source)
}