import { useEffect, useState } from "react";
import GameModal from "../GameModal/GameModal";
import ReactPlayer from "react-player";
import { useLocation } from "react-router";

// Assets //
import GameCardLms from "../../../global-assets/assets/game-card-lms.webp";
import GameCardKDB from "../../../global-assets/assets/game-card-kdb.webp";
import GameCardBen from "../../../global-assets/assets/game-card-ben.webp";
import GameCardBFE from "../../../global-assets/assets/game-card-bfe.webp";
import GameCardIsabella from "../../../global-assets/assets/game-card-isabella.webp";
import GameCardStainedGlass from "../../../global-assets/assets/game-card-stained-glass.webp";
import GameCardMaple from "../../../global-assets/assets/game-card-maple.webp";
import GameCardLittleLight from "../../../global-assets/assets/game-card-little-light.webp";
import GameCardOliver from "../../../global-assets/assets/game-card-oliver.webp";
import GameCardAva from "../../../global-assets/assets/game-card-ava.webp";
import GameCardLumi from "../../../global-assets/assets/game-card-lumi.webp";
import GameCardNER from "../../../global-assets/assets/game-card-ner.webp";
import GameCardBOI from "../../../global-assets/assets/game-card-boi.webp";
import GameCardBUB from "../../../global-assets/assets/game-card-bub.webp";
import GameCardComingSoon from "../../../global-assets/assets/game-card-coming-soon.webp";
import FeatGameCardLms from "../../../global-assets/assets/game-img-feat-lms.webp";
import FeatGameCardNewRelease from "../../../global-assets/assets/new-release-banner-top-left.webp";
import FeatGameCardImgMobile from "../../../global-assets/assets/game-img-feat-lms-mobile.webp";
import GameIcon from "../../../global-assets/assets/game-icon1.svg";
import ThumbAva from "../assets/thumbnail_ava.webp";
import ThumbBen from "../assets/thumbnail_ben.webp";
import ThumbBfe from "../assets/thumbnail_bfe.webp";
import ThumbBoi from "../assets/thumbnail_boi.webp";
import ThumbKdb from "../assets/thumbnail_kdb.webp";
import ThumbLl from "../assets/thumbnail_ll.webp";
import ThumbLof from "../assets/thumbnail_lof.webp";
import ThumbLwl from "../assets/thumbnail_lwl.webp";
import ThumbMaple from "../assets/thumbnail_maple.webp";
import ThumbOvv from "../assets/thumbnail_ovv.webp";
import ThumbSgl from "../assets/thumbnail_sgl.webp";
import ThumbBub from "../assets/thumbnail_bub.webp";
import ThumbLHQ from "../assets/thumbnail_lucas_heavenly_quest.webp";

interface GameMember {
  id?: number;
  title: string;
  featured?: boolean;
  imageSrc: string;
  category: string;
  videoSrc?: string | JSX.Element;
  shareSrc?: string;
  thumbnail?: string;
  description: string;
  newRelease?: boolean;
  comingSoon?: boolean;
}

interface GameMember {
  id?: number;
  title: string;
  featured?: boolean;
  imageSrc: string;
  category: string;
  videoSrc?: string | JSX.Element;
  shareSrc?: string;
  thumbnail?: string;
  description: string;
  newRelease?: boolean;
  comingSoon?: boolean;
  gamePageUrl?: string;
}

export const GameData: GameMember[] = [
  {
    id: 953689893,
    title: "Lucas Mission Starhope",
    imageSrc: GameCardLms,
    category: "Space 3D Shooter",
    videoSrc:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/LMSPreroll.mov",
    shareSrc: "https://vimeo.com/953689893",
    thumbnail: ThumbLHQ,
    description:
      "Lucas discovers a sophisticated Eldavarian rocket ship, the RSS StarHope. Can he defeat the Smudz and find his brother?",
    newRelease: true,
    featured: true,
    gamePageUrl: "/games/lucas-mission-starhope",
  },
  {
    id: 936032354,
    title: "King David's Battles",
    imageSrc: GameCardKDB,
    category: "Action RPG",
    videoSrc:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/kdb_trailer%20(720p).mp4",
    shareSrc: "https://vimeo.com/936032354",
    thumbnail: ThumbKdb,
    description:
      "From a shepherd to a warrior to being crowned King, relive the greatest moments in David's life, and learn how he strengthened himself in the Lord to achieve victory.",
    newRelease: true,
  },
  {
    id: 953604909,
    title: "Chirp Song: Wings of Praise",
    imageSrc: GameCardBUB,
    category: "Strategy, Bubble Shooter",
    videoSrc:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/chirp_song__wings_of_praise_-_official_trailer%20(720p).mp4",
    shareSrc: "https://vimeo.com/953604909",
    thumbnail: ThumbBub,
    description:
      'This charming bubble shooter game set in the RhymVerse reveals the restorative power of praise as players free the "Chirps" in the Forest of Words from Axilla\'s corruption! “Let everything that has breath praise the Lord” (Psalm 150:6).',
    newRelease: true,
  },
  {
    id: 823889094,
    title: "Maple and the Forest of Words",
    imageSrc: GameCardMaple,
    category: "Platformer",
    videoSrc:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/maple_trailer%20(720p).mp4",
    shareSrc: "https://vimeo.com/823889094",
    thumbnail: ThumbMaple,
    description:
      "The mysterious, brooding Forest of Words holds unimaginable dangers. In this side-scrolling action-adventure, Maple the tiger bunny and her fox-bear friend, Oliver, are on a rescue mission. Maple's journey will take her from the treetops of the forest to the depths of the Berindium mines, outwitting ancient corruption, dangerous smudz, and the wicked Axilla herself. Can you help Maple before the power of words is lost in the corrupted forest forever?",
  },
  {
    id: 933107040,
    title: "Book of Imagination",
    imageSrc: GameCardBOI,
    category: "Coloring",
    videoSrc:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/boi_trailer%20(720).mp4",
    shareSrc: "https://vimeo.com/933107040",
    thumbnail: ThumbBoi,
    description:
      "An imaginative coloring game featuring TruPlay's delightful RhymVerse characters - and more!",
  },
  {
    id: 824784067,
    title: "Oliver & the Vindictive Vines",
    imageSrc: GameCardOliver,
    category: "Endless Runner",
    videoSrc:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/ovv_trailer%20(720p).mp4",
    shareSrc: "https://vimeo.com/824784067",
    thumbnail: ThumbOvv,
    description:
      "Join Oliver and his Book of Fox Facts as they race, jump, duck, and dodge through the Forest of Words in this endless runner game. Will Oliver, the Biblical Fox, find the answers he seeks? Will he earn a Bravest Young Botanist Badge? Or will he be tripped and trapped by the dangers in the Forest of Words?",
  },
  {
    id: 823875255,
    title: "Heroes of Rhym: Battle for Eldavar",
    imageSrc: GameCardBFE,
    category: "Tower Defense",
    videoSrc:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/bfe_trailer(720p).mp4",
    shareSrc: "https://vimeo.com/823875255",
    thumbnail: ThumbBfe,
    description:
      "The great Castle Eldavar is in danger, and the only ones who can save it are a group of kids out way past their curfew. This tower defense game pits Maple, Benjamin, Ava, and Lucas against the Smudz forces of the evil queen Axilla. Help the kids use the extraordinary powers of the castle to protect its secrets from the Dark Queen.",
  },
  {
    id: 824208132,
    title: "Ava & the Mirror of Truth",
    imageSrc: GameCardAva,
    category: "Puzzle",
    videoSrc:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/ava_trailer%20(720p).mp4",
    shareSrc: "https://vimeo.com/824208132",
    thumbnail: ThumbAva,
    description:
      "Ava has recently gone through a lot in her life. It's left her feeling insecure about her place in the world. Where does she fit in? This puzzle game exposes the lies we believe about ourselves and shows us how to use God's truth to fight them. Join Ava on a journey through her bedroom mirror that becomes an adventure in her soul. She'll battle snarflon, get help from a sling sloth, and learn the truth about herself—that she always has a place with God.",
  },
  {
    id: 890078808,
    title: "Benjamin and the Armor of Logos",
    imageSrc: GameCardBen,
    category: "Action RPG",
    videoSrc:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/ben_trailer%20(720p).mp4",
    shareSrc: "https://vimeo.com/890078808",
    thumbnail: ThumbBen,
    description:
      "Benjamin is on a mission to find all of the pieces of the Armor of Logos, but vicious Smudz sent by the evil Queen Axilla stand in his way. He must rely on the power of prayer and courage that only comes from God to overcome his foes and recapture the lost armor.",
  },
  {
    id: 824201853,
    title: "Stained Glass",
    imageSrc: GameCardStainedGlass,
    category: "Match 3",
    videoSrc:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/sgl_trailer%20(720p).mp4",
    shareSrc: "https://vimeo.com/824201853",
    thumbnail: ThumbSgl,
    description:
      "Experience the Bible in a whole new way. A fun and beautiful match-3 game that shares the Bible in a way people have never seen before. Match jewel-toned pieces of glass to reveal the stories of the men and women in the Bible in beautiful stained glass panoramas. Featuring the #1 hit song “Oceans” by Hillsong United.",
  },
  {
    id: 856754442,
    title: "Isabella's Leap of Faith",
    imageSrc: GameCardIsabella,
    category: "Endless Jumper",
    videoSrc:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/lof_trailer%20(240p).mp4",
    shareSrc: "https://vimeo.com/856754442",
    thumbnail: ThumbLof,
    description:
      "Jump with Isabella through the Forest of Words, collecting Eldavarian Fyreflies to power the Living Lantern. Choose Isabella's path wisely, making sure only to land on the Fruit of the Spirit. The rotten fruit will wilt away under her feet, making her progress more difficult.",
  },
  // NER is not in Vimeo yet
  {
    // id: ???
    title: "Noah's Elephant in the Room",
    imageSrc: GameCardNER,
    category: "Puzzle",
    videoSrc: (
      <ReactPlayer
        url={require("../../../global-assets/videos/game-card-video-ner.mp4")}
        controls
        width="100%"
        height="100%"
      />
    ),
    // shareSrc: "https://vimeo.com/???",
    // thumbnail: ???,
    description:
      "Take a ride on Noah's pet elephant as he stampedes his way around the ark! See how long you can run without being caught by Noah's sons in this fun and exciting Bible memory game!",
  },
  {
    id: 824780575,
    title: "Little Light",
    imageSrc: GameCardLittleLight,
    category: "Side Scroller",
    videoSrc:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/ll_trailer%20(720p).mp4",
    shareSrc: "https://vimeo.com/824780575",
    thumbnail: ThumbLl,
    description:
      "After a blackout leaves the city in complete darkness, Lumi steps in to share her light. Guide her from lantern to lantern to help her illuminate the dark streets. Meanwhile, scripture brings light to the player as they help Lumi fire up the street lamps.",
  },
  {
    id: 824782629,
    title: "Lumi's Words of Light",
    imageSrc: GameCardLumi,
    category: "Word Game",
    videoSrc:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/lwl_trailer%20(720p).mp4",
    shareSrc: "https://vimeo.com/824782629",
    thumbnail: ThumbLwl,
    description:
      "Lumi's Words of Light opens up a world of God's promises and blessings to the city and to the player. Unscramble the word puzzle and you will find that each word sheds new light on God's everlasting and unconditional love for you.",
  },
];

export default function GamesMoreMain() {
  const THRESHOLD = 4;

  useEffect(() => {
    const motionMatchMedia = window.matchMedia("(prefers-reduced-motion)");

    function handleHover(e: any) {
      const { clientX, clientY, currentTarget } = e;
      const { clientWidth, clientHeight, offsetLeft, offsetTop } =
        currentTarget;

      const horizontal = (clientX - offsetLeft) / clientWidth;
      const vertical = (clientY - offsetTop) / clientHeight;
      const rotateX = (THRESHOLD / 2 - horizontal * THRESHOLD).toFixed(2);
      const rotateY = (vertical * THRESHOLD - THRESHOLD / 2).toFixed(2);

      currentTarget.style.transform = `perspective(${clientWidth}px) rotateX(${rotateY}deg) rotateY(${rotateX}deg) scale3d(1, 1, 1)`;
    }

    function resetStyles(e: any) {
      e.currentTarget.style.transform = `perspective(${e.currentTarget.clientWidth}px) rotateX(0deg) rotateY(0deg)`;
    }

    if (!motionMatchMedia.matches) {
      Object.entries(GameData).forEach(([index, member]) => {
        const card = document.querySelector(`.tru-main-game-image-${index}`);
        if (card) {
          card.addEventListener("mousemove", handleHover);
          card.addEventListener("mouseleave", resetStyles);
        }
      });
    }

    return () => {
      Object.entries(GameData).forEach(([index, _]) => {
        const card = document.querySelector(`.tru-main-game-image-${index}`);
        if (card) {
          card.removeEventListener("mousemove", handleHover);
          card.removeEventListener("mouseleave", resetStyles);
        }
      });
    };
  }, []);

  const [isVideoVisible, setIsVideoVisible] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<GameMember | null>(null);

  const toggleVideoVisibility = (member: GameMember) => {
    setSelectedMember(member);
    setIsVideoVisible(!isVideoVisible);
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const videoToShow: string | null = params.get("video");

  useEffect(() => {
    if (videoToShow) {
      let videoIndex = null;
      GameData.forEach((game, index) => {
        if (game.id == Number(videoToShow)) {
          videoIndex = index;
          return;
        }
      });
      if (videoIndex || videoIndex === 0) {
        toggleVideoVisibility(GameData[videoIndex]);
      }
    }
  }, [videoToShow]);

  return (
    <div className="relative overflow-hidden px-5 py-12 pt-[25px] sm:px-6 sm:py-10 md:px-8 md:py-20 lg:px-12 lg:py-24">
      <div className="container mx-auto">
        <div className="max-w-screen-full mx-auto text-center">
          <div className="mb-8 md:mb-16">
            <h2 className="text-2xl font-bold text-cerulean-500 md:text-5xl">
              Our Library of Beautiful Christian Games
            </h2>
          </div>
        </div>
        <div className="mb-14">
          <div
            className="flex transform flex-col items-stretch md:flex-row"
            onClick={() => {
              const game = Object.values(GameData)[0];
              if (game.gamePageUrl) {
                window.open(game.gamePageUrl, "_blank");
              } else {
                toggleVideoVisibility(game);
              }
            }}
          >
            <div className="flex-1 cursor-pointer overflow-hidden rounded-t-[20px] md:rounded-l-[40px] md:rounded-tr-none">
              <div className="relative h-full w-full">
                <img
                  alt=""
                  loading="lazy"
                  src={FeatGameCardLms}
                  className="hidden h-full w-full rounded-t-[20px] object-cover transition-transform duration-300 hover:scale-105 md:block md:rounded-l-[40px] md:rounded-t-none"
                />
                {FeatGameCardImgMobile && (
                  <img
                    alt=""
                    loading="lazy"
                    src={FeatGameCardImgMobile}
                    className="h-full w-full rounded-t-[20px] object-cover transition-transform duration-300 hover:scale-105 md:hidden md:rounded-l-[40px] md:rounded-t-none"
                  />
                )}
                {GameData[0].newRelease && (
                  <img
                    alt=""
                    loading="lazy"
                    src={FeatGameCardNewRelease}
                    className="absolute left-0 top-0 w-36 md:w-56"
                  />
                )}
              </div>
            </div>
            <div className="flex flex-1 flex-col justify-center rounded-b-[40px] bg-white bg-opacity-5 p-8 sm:rounded-b-[30px] md:rounded-b-none md:rounded-r-[40px]">
              <div className="mb-2 flex items-center">
                <img src={GameIcon} loading="lazy" alt="" className="mr-5" />
                <div className="font-display text-lg font-medium text-gray-600 md:text-2xl">
                  {Object.values(GameData)[0].category}
                </div>
              </div>
              <h3 className="mb-2 font-bold text-cerulean-500 md:text-4xl">
                {Object.values(GameData)[0].title}
              </h3>
              <p className="mb-0 text-white">
                {Object.values(GameData)[0].description}
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-14 gap-y-10 md:grid-cols-2">
          {GameData.slice(1).map((game, index) => (
            <div
              key={game.title}
              className="cursor-pointer"
              onClick={() => toggleVideoVisibility(game)}
            >
              <div className="perspective-1000 relative">
                <div
                  className={`tru-main-game-image mb-6 overflow-hidden rounded-[40px] tru-main-game-image-${index + 1}`}
                >
                  <img
                    src={game.imageSrc}
                    loading="lazy"
                    alt=""
                    className="h-full w-full object-cover"
                  />
                  {game.newRelease && (
                    <img
                      alt=""
                      loading="lazy"
                      src={FeatGameCardNewRelease}
                      className="absolute left-0 top-0 w-36 md:w-44"
                    />
                  )}
                </div>
                <div className="mb-1 flex items-center">
                  <img src={GameIcon} loading="lazy" alt="" className="mr-4" />
                  <h5 className="text-base font-bold text-cerulean-500 md:text-2xl">
                    {game.title}
                  </h5>
                </div>
                <div className="mt-2 flex items-center font-display text-lg font-medium text-gray-600 md:text-2xl">
                  {game.category}
                </div>
              </div>
            </div>
          ))}
          {/* More Games Coming Soon card hidden on tablet and desktop for now */}
          <div className="cursor-pointer md:hidden">
            <div className="perspective-1000 relative">
              <div className="mb-6 overflow-hidden rounded-lg">
                <img
                  src={GameCardComingSoon}
                  loading="lazy"
                  alt=""
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="mb-1 flex items-center">
                <h5 className="font-bold text-cerulean-500 md:text-2xl">
                  Wait till you see what we're working on next!
                </h5>
              </div>
            </div>
          </div>
        </div>
        {isVideoVisible && selectedMember ? (
          <GameModal
            selectedGame={selectedMember}
            setIsVideoVisible={setIsVideoVisible}
          />
        ) : null}
      </div>
    </div>
  );
}
