import { useEffect } from "react";

type ScriptConfig = {
  src: string;
  id?: string;
  async?: boolean;
  defer?: boolean;
  priority?: "critical" | "high" | "medium" | "low";
};

const thirdPartyScripts: ScriptConfig[] = [
  {
    src: "https://www.googletagmanager.com/gtag/js",
    id: "google-tag-manager",
    async: true,
    priority: "critical",
  },
  {
    src: "https://www.googletagmanager.com/gtm.js",
    id: "gtm-script",
    async: true,
    priority: "critical",
  },
  {
    src: "https://script.hotjar.com/modules.js",
    id: "hotjar",
    async: true,
    priority: "high",
  },
  {
    src: "https://analytics.tiktok.com/main.js",
    id: "tiktok-pixel",
    async: true,
    priority: "medium",
  },
  {
    src: "https://snap.licdn.com/li.lms-analytics/insight.min.js",
    id: "linkedin-pixel",
    async: true,
    priority: "medium",
  },
  {
    src: "https://connect.facebook.net/en_US/fbevents.js",
    id: "facebook-pixel",
    async: true,
    priority: "low",
  },
  {
    src: "https://static.ads-twitter.com/uwt.js",
    id: "twitter-pixel",
    async: true,
    priority: "low",
  },
  {
    src: "https://cdnjs.cloudflare.com/ajax/libs/crypto-js/4.0.0/crypto-js.min.js",
    id: "crypto-js",
    async: true,
    priority: "low",
  },
];

export const useScriptLoader = () => {
  useEffect(() => {
    if (typeof window === "undefined") return;

    const loadScript = (script: ScriptConfig) => {
      if (document.getElementById(script.id || "")) return;

      const scriptElement = document.createElement("script");
      scriptElement.src = script.src;
      if (script.id) scriptElement.id = script.id;
      if (script.async) scriptElement.async = true;
      if (script.defer) scriptElement.defer = true;
      document.body.appendChild(scriptElement);
    };

    // Load critcal immediately
    thirdPartyScripts
      .filter((script) => script.priority === "critical")
      .forEach(loadScript);

    // High prio
    window.addEventListener("load", () => {
      thirdPartyScripts
        .filter((script) => script.priority === "high")
        .forEach(loadScript);
    });

    // Medium prio
    setTimeout(() => {
      thirdPartyScripts
        .filter((script) => script.priority === "medium")
        .forEach(loadScript);
    }, 3000);

    // Low prio
    setTimeout(() => {
      thirdPartyScripts
        .filter((script) => script.priority === "low")
        .forEach(loadScript);
    }, 5000);

    // Cleanup
    return () => {
      window.removeEventListener("load", () => {
        thirdPartyScripts
          .filter((script) => script.priority === "high")
          .forEach(loadScript);
      });
    };
  }, []);
};

export default useScriptLoader;
