import { useEffect, useState, useContext } from "react";
import "./global-assets/css/App.css";
import "./global-assets/css/Gradients.css";
import "./global-assets/css/ToastOverrides.css";
import "./global-assets/css/index.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  isAuthenticatedWithValidToken,
  Logout,
  navigateTo,
  SessionAuthContext,
} from "./config/UserAuthContext";
import { EnvironmentConfigContext } from "./config/Environment";
import { FeatureFlagProvider } from "./config/FeatureFlagContext";
import { FBRemoteConfigProvider } from "./config/FBRemoteConfigContext";
import {
  GA4AnalyticsContext,
  MixPanelAnalyticsContext,
} from "./components/analytics/Analytics";
import { HelmetProvider } from "react-helmet-async";
import Cookies from "js-cookie";
import { useScriptLoader } from "./hooks/useScriptLoader";
import SEO from "./components/common/SEO";

// ****** Elements ****** //
import DeleteAccount from "./components/account/AccountDelete/AccountDelete";
import ForgotPassword from "./components/auth/AuthPages/ForgotPassword/ForgotPassword";
import VerifyEmailAddress from "./components/auth/AuthPages/VerifyEmailAddress/VerifyEmailAddress";
import { SetNewPassword } from "./components/auth/AuthPages/SetNewPassword/SetNewPassword";
import { CookieConsentModal } from "./components/notification/CookieConsentModal/CookieConsentModal";

// ****** Pages ****** //
import PaymentCompleted from "./pages/ThankYouPages/PaymentCompleted";
import BangoThankYou from "./pages/Bango/BangoThankYou";
import DownloadApp from "./pages/ThankYouPages/DownloadApp";
import GenerateImmediateStripeRedirect from "./pages/ThankYouPages/GenerateImmediateStripeRedirect";
import GiftPurchaseSuccess from "./pages/ThankYouPages/GiftPurchaseSuccess";
import GiftASub from "./pages/GiftASub/GiftASubscription";
import AboutWhoWeAre from "./pages/About/AboutWhoWeAre";
import Careers from "./pages/Careers/Careers";
import Contact from "./pages/Contact/Contact";
import PromiseKeepers from "./pages/PromiseKeepers/PromiseKeepers";
import { AccountSettings } from "./pages/AccountSettings/AccountSettings";
import { NoMatch } from "./pages/NoMatch/NoMatch";
import GamesAndMore from "./pages/GamesAndMore/GamesAndMore";
import RedeemCode from "./pages/RedeemCode/RedeemCode";
import PrivacyPolicy from "./pages/PrivacyAndTerms/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/PrivacyAndTerms/TermsAndConditions/TermsAndConditions";
import FAQ from "./pages/FAQ/FAQ";
import SSOLogin from "./pages/Login/SSOLogin";
import SSOSignUp from "./pages/RegistrationFlow/SSOSignUp";
import PlanSelection from "./pages/RegistrationFlow/PlanSelection";
import FreeContent from "./pages/FreeContent/FreeContent";
// import Crowdfunding from "./pages/Crowdfunding/Crowdfunding";
import LMS from "./pages/GamesAndMore/LMS/LMS";
import ParentsHub from "./pages/ParentsHub/ParentsHub";
// ****** Shared ****** //
import Footer from "./components/nav/Footers/Footer";
import TopNavbar from "./components/nav/TopNavbar/TopNavbar";
import BlogList from "./pages/Blog/Blog";
import BlogPost from "./components/blog/BlogPost";
import NewsPage from "./pages/Blog/News";

// ****** Marketing Landers ****** //
import HomeLanding from "./pages/HomeLanding/HomeLanding";
import TruPlayAppsLanding from "./pages/MarketingLandingPages/TruPlayAppsLanding";
import AffiliateMain from "./pages/MarketingLandingPages/AffiliatePages/AffiliateMain";
import AffiliateIndividual from "./pages/MarketingLandingPages/AffiliatePages/AffiliateIndividual";
import AffiliateGroup from "./pages/MarketingLandingPages/AffiliatePages/AffiliateGroup";
import AffiliateMarketer from "./pages/MarketingLandingPages/AffiliatePages/AffiliateMarketer";
import EmailRedirect from "./pages/EmailRedirect/EmailRedirect";
import { PulseLoader } from "react-spinners";
import BangoActivationError from "./pages/Bango/BangoActivationError";
import PageWrapper from "./components/common/PageWrapper";
import ChirpSong from "./pages/GamesAndMore/ChirpSong/Chirpsong";
import MiniMobile1 from "./pages/MarketingLandingPages/MobileLandingPages/MiniMobileLander1";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

function TruPlayApp() {
  const environmentConfig = useContext(EnvironmentConfigContext);
  const currentUserAuthContext = useContext(SessionAuthContext);
  const analyticsContext = useContext(MixPanelAnalyticsContext);
  const ga4AnalyticsContext = useContext(GA4AnalyticsContext);

  // Optomize page load by loading scripts in background
  useScriptLoader();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loginData, setLoginData] = useState(null);
  const [showConsentModal, setShowConsentModal] = useState<boolean>(false);
  const [authCheckLoading, setAuthCheckLoading] = useState<boolean>(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      const isAuthenticatedNow = isAuthenticatedWithValidToken();
      setIsAuthenticated(isAuthenticatedNow);
      setAuthCheckLoading(false);
    };

    checkAuthentication();

    if (loginData && !isAuthenticated) {
      Logout();
      navigateTo("/login");
    }

    const hasVisited = Cookies.get("hasVisited");
    if (!hasVisited) {
      setShowConsentModal(true);
    }
  }, [isAuthenticated, loginData]);

  const handleCloseConsentModal = () => {
    Cookies.set("hasVisited", "true", { expires: 365 });
    setShowConsentModal(false);
  };

  return (
    <EnvironmentConfigContext.Provider value={environmentConfig}>
      <SessionAuthContext.Provider value={currentUserAuthContext}>
        <GA4AnalyticsContext.Provider value={ga4AnalyticsContext}>
          <MixPanelAnalyticsContext.Provider value={analyticsContext}>
            <HelmetProvider>
              <FeatureFlagProvider>
                <FBRemoteConfigProvider>
                  <ToastContainer theme="colored" />
                  <Router>
                    <div className="relative h-full min-h-[calc(100vh-272px)]">
                      {showConsentModal && (
                        <CookieConsentModal onClose={handleCloseConsentModal} />
                      )}
                      <SEO />
                      <Routes>
                        <Route
                          path="/about"
                          element={
                            <>
                              <TopNavbar />
                              <AboutWhoWeAre />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/blog"
                          element={
                            <>
                              <TopNavbar />
                              <BlogList />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/blog/:slug"
                          element={
                            <>
                              <TopNavbar />
                              <BlogPost />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/news"
                          element={
                            <>
                              <TopNavbar />
                              <NewsPage />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/careers"
                          element={
                            <>
                              <TopNavbar />
                              <Careers />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/contact"
                          element={
                            <>
                              <TopNavbar />
                              <Contact />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/games-more"
                          element={<Navigate replace to="/games" />}
                        />
                        <Route
                          path="/user-registration"
                          element={<Navigate replace to="/signup" />}
                        />
                        <Route
                          path="/games"
                          element={
                            <>
                              <TopNavbar />
                              <GamesAndMore />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/games/lucas-mission-starhope"
                          element={
                            <>
                              <TopNavbar />
                              <LMS />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/games/Chirpsong"
                          element={
                            <>
                              <TopNavbar />
                              <ChirpSong />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/privacy-policy"
                          element={
                            <>
                              <TopNavbar />
                              <PrivacyPolicy />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/terms-and-conditions"
                          element={
                            <>
                              <TopNavbar />
                              <TermsAndConditions />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/faq"
                          element={
                            <>
                              <TopNavbar />
                              <FAQ />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/"
                          element={
                            <>
                              <TopNavbar showBanner={false} />
                              <PageWrapper showBanner={false}>
                                <HomeLanding />
                              </PageWrapper>
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/redeem"
                          element={
                            <>
                              <TopNavbar />
                              <RedeemCode />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/gift"
                          element={
                            <>
                              <TopNavbar navType="gift" />
                              <GiftASub />
                              <Footer alt />
                            </>
                          }
                        />
                        <Route
                          path="/download-app"
                          element={
                            <>
                              <TopNavbar />
                              <DownloadApp />
                              <Footer thankYou />
                            </>
                          }
                        />
                        <Route
                          path="/generate-gifting-code"
                          element={
                            <>
                              <TopNavbar />
                              <GenerateImmediateStripeRedirect configName="pk_gifting" />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/parents"
                          element={
                            <>
                              <TopNavbar />
                              <ParentsHub />
                              <Footer />
                            </>
                          }
                        />
                        <Route path="/w2a" element={<MiniMobile1 />} />
                        {isAuthenticated ? (
                          <Route
                            path="/signup"
                            element={<Navigate to="/pricing?fwd=true" />}
                          />
                        ) : (
                          <Route
                            path="/signup"
                            element={
                              <>
                                <SSOSignUp
                                  setAuth={setIsAuthenticated}
                                  setLoginData={setLoginData}
                                />
                              </>
                            }
                          />
                        )}
                        {isAuthenticated ? (
                          <Route
                            path="/login"
                            element={
                              <Navigate to="/account-settings?fwd=true" />
                            }
                          />
                        ) : (
                          <Route
                            path="/login"
                            element={
                              <>
                                <SSOLogin
                                  setAuth={setIsAuthenticated}
                                  setLoginData={setLoginData}
                                />
                                <Footer />
                              </>
                            }
                          />
                        )}
                        <Route
                          path="/affiliate"
                          element={
                            <>
                              <TopNavbar />
                              <AffiliateMain />
                            </>
                          }
                        ></Route>
                        <Route
                          path="/affiliate_individual"
                          element={
                            <>
                              <TopNavbar />
                              <AffiliateIndividual />
                            </>
                          }
                        ></Route>
                        <Route
                          path="/affiliate_group"
                          element={
                            <>
                              <TopNavbar />
                              <AffiliateGroup />
                            </>
                          }
                        ></Route>
                        <Route
                          path="/affiliate_marketer"
                          element={
                            <>
                              <TopNavbar />
                              <AffiliateMarketer />
                            </>
                          }
                        ></Route>
                        <Route
                          path="/forgot-password"
                          element={
                            <>
                              <TopNavbar />
                              <ForgotPassword />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/truplay-apps"
                          element={
                            <>
                              <TopNavbar navType="truplay-apps" />
                              <TruPlayAppsLanding />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/promise-keepers"
                          element={<PromiseKeepers />}
                        />
                        <Route
                          path="/delete-account"
                          element={
                            <>
                              <TopNavbar />
                              <DeleteAccount />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/verify-email-address"
                          element={
                            <>
                              <TopNavbar />
                              <VerifyEmailAddress />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/set-new-password"
                          element={
                            <>
                              <TopNavbar />
                              <SetNewPassword setAuth={setIsAuthenticated} />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/gift-success"
                          element={
                            <>
                              <TopNavbar />
                              <GiftPurchaseSuccess />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/account-settings"
                          element={
                            <>
                              <TopNavbar />
                              <AccountSettings setAuth={setIsAuthenticated} />
                              <Footer />
                            </>
                          }
                        />
                        <Route
                          path="/free-content"
                          element={
                            <>
                              <TopNavbar />
                              <FreeContent />
                              <Footer />
                            </>
                          }
                        />
                    <Route
                          path="/select-plan"
                          element={<Navigate replace to="/pricing" />}
                        />
                        <Route path="/pricing" element={<PlanSelection />} />
                        <Route
                          path="/email-redirect"
                          element={
                            <>
                              <TopNavbar />
                              <EmailRedirect setAuth={setIsAuthenticated} />
                              <Footer />
                            </>
                          }
                        />
                        {isAuthenticated && (
                          <>
                            <Route
                              path="/thank-you"
                              element={
                                <>
                                  <TopNavbar />
                                  <PaymentCompleted />
                                  <Footer thankYou />
                                </>
                              }
                            />
                          </>
                        )}
                        <Route
                          path="/bango-thank-you"
                          element={
                            <>
                              <TopNavbar />
                              <BangoThankYou />
                              <Footer thankYou />
                            </>
                          }
                        />
                        <Route
                          path="/bango-activation-error"
                          element={
                            <>
                              <TopNavbar />
                              <BangoActivationError />
                              <Footer thankYou />
                            </>
                          }
                        />
                        <Route
                          path="*"
                          element={
                            authCheckLoading ? (
                              <div className="flex min-h-screen items-center justify-center bg-indigo-900">
                                <PulseLoader color="#fff" />
                              </div>
                            ) : (
                              <>
                                <TopNavbar />
                                <NoMatch />
                                <Footer />
                              </>
                            )
                          }
                        />
                      </Routes>
                    </div>
                  </Router>
                </FBRemoteConfigProvider>
              </FeatureFlagProvider>
            </HelmetProvider>
          </MixPanelAnalyticsContext.Provider>
        </GA4AnalyticsContext.Provider>
      </SessionAuthContext.Provider>
    </EnvironmentConfigContext.Provider>
  );
}

export default TruPlayApp;
