import { lazy, Suspense } from "react";
import SEO from "../../../components/common/SEO";

const PrivacyContent = lazy(() => import("./PrivacyContent"));

export default function PrivacyPolicy() {
  return (
    <>
      <div className="relative w-full overflow-hidden bg-[#00203f] p-[50px_20px] pt-[100px] md:p-[75px_20px] md:pt-[125px] lg:pb-[75px]">
        <div className="mx-auto max-w-[1440px]">
          <div className="mx-auto mb-0 flex max-w-[820px] flex-col items-center justify-start text-center">
            <div className="relative mb-0">
              <h2 className="text-[#37a6d4]">Privacy Policy</h2>
            </div>
          </div>
        </div>
      </div>

      <SEO
        title="Privacy Policy | TruPlay Games"
        canonicalUrl="https://www.truplaygames.com/privacy-policy"
        description="Your privacy matters at TruPlay. Review our privacy policy to understand how we protect and use the information of our users while they enjoy our Christian content and games."
      />

      <Suspense
        fallback={<div className="p-[50px_20px] text-center">Loading...</div>}
      >
        <PrivacyContent />
      </Suspense>
    </>
  );
}
