interface IPageWrapperProps {
  children: JSX.Element;
  showBanner?: boolean;
}

export default function PageWrapper({
  children,
  showBanner = true,
}: IPageWrapperProps) {
  return (
    <div className={showBanner ? "pt-[86px] md:pt-[72px]" : ""}>{children}</div>
  );
}
