import { useEffect } from "react";
import { isIOS, isAndroid } from "react-device-detect";

// hooks //
import { useAnalytics } from "../../../hooks/useAnalytics";

// context //
import { navigateTo } from "../../../config/UserAuthContext";

// Components //
import Button from "../../../components/common/Button";

// Images //
import Characters from "../../../global-assets/assets/characters-mobile.webp";
import TruplayLargeLogoShadow from "../../../global-assets/assets/truplay-logo-large-shadow.webp";

const MiniMobile1 = () => {
  const analytics = useAnalytics();
  const IOS_STORE_URL =
    "https://apps.apple.com/us/app/truplay-christian-games/id1594689215";
  const ANDROID_STORE_URL =
    "https://play.google.com/store/apps/details?id=com.truplaygames.platformclient001&hl=en-US";

  const deviceType = isIOS ? "iOS" : isAndroid ? "Android" : "Desktop";

  const handleStoreRedirect = (linkType: string, buttonId: string) => {
    analytics.trackEvent("select_content", {
      content_type: linkType,
      button_id: buttonId,
      os_type: deviceType,
    });

    if (isIOS) {
      window.location.href = IOS_STORE_URL;
    } else if (isAndroid) {
      window.location.href = ANDROID_STORE_URL;
    } else {
      navigateTo("/");
    }
  };

  useEffect(() => {
    analytics.trackEvent("page_view", {
      page_name: "w2a",
      os_type: deviceType,
    });

    const timer = setTimeout(
      () =>
        handleStoreRedirect(
          "w2a_auto_redirect",
          "w2a_auto_redirect_cta_button"
        ),
      5000
    );

    return () => clearTimeout(timer);
  }, [analytics, deviceType]);

  return (
    <div>
      <div className="relative flex h-[100vh] flex-col items-center justify-center gap-5 overflow-hidden">
        <div className="text-center">
          <img src={TruplayLargeLogoShadow} alt="Truplay" className="w-[50%]" />
        </div>
        <div>
          <img
            src={Characters}
            alt="Truplay"
            className="max-w-[600px] md:w-full md:max-w-none"
          />
        </div>
        <h5 className="max-w-52 pb-4 text-center text-2xl text-white">
          Turn Game Time Into God Time
        </h5>
        <Button
          id="get_started_btn"
          onClick={() =>
            handleStoreRedirect("w2a_redirect", "w2a_redirect_cta_button")
          }
          btnType="green"
          className="z-[2] !px-16"
        >
          Go To App Store
        </Button>
      </div>
    </div>
  );
};

export default MiniMobile1;
