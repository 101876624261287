import { useContext, useEffect, useState, useRef } from "react";
import { DeviceSessionContext } from "../../components/analytics/DeviceContext";
import Utm from "../../components/analytics/UTM";
import { MixPanelAnalyticsContext } from "../../components/analytics/Analytics";
import TagManager from "react-gtm-module";
import { GetOrCreateSessionAuthContext, navigateTo } from "../../config/UserAuthContext";
import Cookies from "js-cookie";

// Packages //
import { useLocation } from "react-router";
import { handleAutoPlay } from "../../lib/HelperFunctions";
import SEO from "../../components/common/SEO";

// Hooks //
import { useVideoManagement } from "../../hooks/useVideoManagement";
import { useIsVisible } from "../../hooks/useIsVisible";

// Components //
import PageHeaderButton from "../../components/common/PageHeaderButton";
import ImageInfoSection from "../../components/common/ImageInfoSection";
import CardsCTA from "../../components/common/CardsCTA";
import VideoReviewSlider from "../../components/common/VideoReviewsSlider";

// Images //
import dadSon from "../../global-assets/assets/Dad-Son.webp";
import AffiliateIndHeader from "../../global-assets/assets/AffiliateIndHeader.webp";
import AppGameTiles from "../../global-assets/assets/App_Game_Tiles.webp";
import ThumbDemo from "./assets/thumbnail_homepage_demo.webp";
import ThumbHeroHuckabee from "./assets/thumbnail_hero_huckabee.webp";

// Data //
import { ParentsHubData } from "./data/ParentsHubData";

interface VideoInfo {
  [key: string]: {
    id: number;
    url: string;
    title: string;
    autoPlay?: boolean;
    visible: boolean;
    loop?: boolean; // Default is false
    muted?: boolean; // Default is false
    playsinline?: boolean; // Default is true
    shareLink?: string;
    thumbnail?: string;
  };
}

const videoDefaults = {
  ipadVideo: {
    id: 946337337,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/homepage_demo%20(720p).mp4",
    title: "Discover TruPlay Games",
    autoPlay: false,
    visible: false,
    loop: true,
    muted: true,
    shareLink: "https://vimeo.com/946337337",
    thumbnail: ThumbDemo,
  },
  heroHuckabeeVideo: {
    id: 945854154,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/hero-bullet-points-huckabee.mp4",
    title: "Discover TruPlay Games",
    visible: false,
    shareLink: "https://vimeo.com/945854154",
    thumbnail: ThumbHeroHuckabee,
  },
};

interface VideoReview {
  idx: number;
  url: string;
  width: string | number;
  height: string | number;
  component: JSX.Element | null;
  visible: boolean;
  focus: boolean;
  title: string;
  thumbnail: string;
  shareLink: string;
  videoId: string | number;
}

const videoReviews: VideoReview[] = [
  {
    idx: 1,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/homepage_demo%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 1",
    thumbnail: ThumbDemo,
    shareLink: "https://vimeo.com/943820182",
    videoId: 943820182,
  },
  {
    idx: 2,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/homepage_demo%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 2",
    thumbnail: ThumbDemo,
    shareLink: "https://vimeo.com/935498545",
    videoId: 935498545,
  },
  {
    idx: 3,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/homepage_demo%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 3",
    thumbnail: ThumbDemo,
    shareLink: "https://vimeo.com/935518118",
    videoId: 935518118,
  },
  {
    idx: 4,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/homepage_demo%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 4",
    thumbnail: ThumbDemo,
    shareLink: "https://vimeo.com/943824997",
    videoId: 943824997,
  },
  {
    idx: 5,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/homepage_demo%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 5",
    thumbnail: ThumbDemo,
    shareLink: "https://vimeo.com/935509797",
    videoId: 935509797,
  },
];

const ParentsHub = () => {
  const mixPanelAnalytics = useContext(MixPanelAnalyticsContext);
  const [deviceCtx] = useState<DeviceSessionContext>(
    new DeviceSessionContext()
  );
  const initialUserContext = GetOrCreateSessionAuthContext();

  const [userContext] = useState(initialUserContext);
  const GlobalUserId = Cookies.get("GlobalUserId");
  const carouselRef = useRef(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const videoToShow: string | null = params.get("video");
  const ipadVideoRef = useRef<HTMLDivElement>(null);
  const isIpadVideoVisible = useIsVisible(ipadVideoRef, 1.0);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        page_name: "marketing: Parents Hub",
        page_type: "marketing",
        os_type: deviceCtx.os.name,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    let utm = new Utm(mixPanelAnalytics);
    utm.track("Web: Parents-Hub-Page-Viewed", deviceCtx);
  }, [
    deviceCtx,
    mixPanelAnalytics,
    userContext.subscription.sources,
    GlobalUserId,
  ]);

  // Add a video to the visibleVidoes object to enable autoplay once a ref
  // and isVisible check has been created for it
  useEffect(() => {
    const visibleVideos = {
      ipadVideo: isIpadVideoVisible,
    };
    handleAutoPlay(
      visibleVideos,
      currentAutoPlayVideos,
      setCurrentAutoPlayVideos
    );

    const videoToPlay: any = document.getElementById(
      videoDefaults.ipadVideo.id.toString() + "_mobile" || ""
    );
    if (videoToPlay) {
      if (currentAutoPlayVideos?.ipadVideo.autoPlay) {
        videoToPlay.play();
      } else {
        videoToPlay.pause();
      }
    }

    // Missing deps will causes infinite re-render, ignoring deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIpadVideoVisible]);

  const ctaRef: any = useRef(null);

  const {
    currentAutoPlayVideos,
    setCurrentAutoPlayVideos,
    shiftedVideos,
    handleCenterOnVideo,
  } = useVideoManagement(videoDefaults, videoReviews, videoToShow);

  const handleGetStartedClick = () => {
    if (ctaRef.current) {
      const topPosition =
        ctaRef.current.getBoundingClientRect().top + window.pageYOffset;
      const offset = 100;
      window.scrollTo({ top: topPosition - offset, behavior: "smooth" });
    }

    TagManager.dataLayer({
      dataLayer: {
        event: "select_content",
        content_type: "link",
        link_name: "parentsHubLearnMore",
        os_type: deviceCtx.os.name,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });
  };

  const ctaClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "select_content",
        content_type: "link",
        link_name: "newsArticleClick",
        os_type: deviceCtx.os.name,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    window.open("https://www.truplaygames.com/resources/news", "_blank");
  };

  const learnMoreClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "select_content",
        content_type: "link",
        link_name: "Learn More Parents Hub",
        link_url: "/games",
        os_type: deviceCtx.os.name,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    navigateTo("/games");
  };

  return (
    <div>
      <SEO
        title="For Parents | Safety and Educational Value | Truplay Games"
        description="Learn how Truplay Games ensures safety and fosters learning for kids."
        canonicalUrl="https://www.truplaygames.com/parents"
      />
      <div
        id="test_data_Parents_Hub_page"
        className="min-h-screen overflow-x-hidden bg-cerulean-100"
      >
        <PageHeaderButton
          backgroundImage={AffiliateIndHeader}
          isActive={true}
          title="Parents Hub"
          subheading="Faith based insight on the latest games, movies, and more"
          buttonLabel="Learn More"
          onButtonClick={handleGetStartedClick}
        />
        <ImageInfoSection
          image={dadSon}
          title="Faith based insight on the latest games, movies, and more."
          description="Browse our collection of detailed commentary on the latest games, movies, and more."
          imagePosition="right"
        />
        <CardsCTA
          buttonLabel="View All"
          onButtonClick={ctaClick}
          cardsData={ParentsHubData}
        />
        <div ref={ctaRef} />
        <div className="flex flex-col gap-12 bg-white pb-20 pt-20">
          <div className="flex flex-col gap-4">
            <h2 className="text-transparent bg-gradient_text bg-clip-text text-center text-3xl lg:text-5xl">
              Weekly Update
            </h2>
            <p className="text-center font-normal text-cool-gray-900">
              Hear what Brent has to say about trends in the gaming industry,
              culteral updates, and more!
            </p>
          </div>
          <div ref={carouselRef} className="flex flex-col gap-16">
            <VideoReviewSlider
              videos={shiftedVideos ? shiftedVideos : videoReviews}
              noTitle
            />
          </div>
        </div>
        <ImageInfoSection
          image={AppGameTiles}
          title="Want peace of mind screen time for your kids?"
          description="Discover the exciting world of TruPlay. Find out how TruPlay is revolutionizing the industry. Providing safe games, shows, comics, and more."
          imagePosition="right"
          buttonText="Learn More"
          imageWidth="w-[450px]"
          onButtonClick={learnMoreClick}
          buttonWidth="w-[90%]"
        />
      </div>
    </div>
  );
};

export default ParentsHub;
