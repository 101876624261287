// Components //
import Button from "./Button";

interface CardProps {
  title: any;
  icon: any;
  description: any;
  url?: string;
}

const Card = ({ icon, title, description, url }: CardProps) => (
  <div
    className={`flex h-[325px] w-[325px] flex-col items-center justify-center gap-2 rounded-[40px] bg-white px-12 shadow-[0_4px_10px_rgba(0,0,0,0.1)] md:h-[380px] md:w-[380px] ${
      url ? "cursor-pointer transition-transform hover:scale-105" : ""
    }`}
    onClick={() => {
      if (url) {
        window.open(url, "_blank", "noopener,noreferrer");
      }
    }}
  >
    <img src={icon} alt={title} className="mx-auto max-w-[100px]" />
    <h3 className="text-transparent bg-gradient_text bg-clip-text text-center font-display text-3xl font-bold">
      {title}
    </h3>
    <p className="m-0 text-base font-normal !leading-normal text-cool-gray-900 md:text-lg">
      {description}
    </p>
  </div>
);

interface CardSectionProps {
  cardsData: Array<{
    title: any;
    icon: any;
    description: any;
    url?: string;
  }>;
  buttonLabel: any;
  onButtonClick: any;
}

const CardsCTA = ({
  cardsData,
  buttonLabel,
  onButtonClick,
}: CardSectionProps) => (
  <div className="flex flex-col gap-12 px-12 pb-20 text-center">
    <div className="flex flex-col items-center justify-center gap-5 md:flex-row md:flex-wrap">
      {cardsData.map((card: any, index: any) => (
        <Card key={index} {...card} />
      ))}
    </div>
    <div className="flex justify-center">
      <Button
        id="get_started_btn"
        onClick={onButtonClick}
        btnType="green"
        className="w-[600px]"
      >
        {buttonLabel}
      </Button>
    </div>
  </div>
);

export default CardsCTA;
