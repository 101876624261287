import { useState, useEffect } from "react";

// Components
import Button from "../../../components/common/Button";
import { toast } from "react-toastify";
import ModalClosable from "../../../components/common/ModalClosable";

// Context
import { GetOrCreateSessionAuthContext } from "../../../config/UserAuthContext";
import AccountServiceClient from "../../../lib/ApiFunction";

// Assets
import Ava from "./assets/Ava.webp";

interface Avatar {
  avatarId: number;
  avatarImageURL: string;
  avatarName: string;
}

interface Profile {
  avatarId: number;
  uuId: string;
  name: string;
}

export default function AccountProfiles() {
  const userContext = GetOrCreateSessionAuthContext();
  const { GetProfileInfo } = AccountServiceClient();
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [selectedAvatar, setSelectedAvatar] = useState<Avatar | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [profileToDelete, setProfileToDelete] = useState<Profile | null>(null);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [updatedName, setUpdatedName] = useState<string>("");

  const [avatars, setAvatars] = useState<Avatar[]>([]);
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [profileData, setProfileData] = useState({
    name: "",
  });

  const toggleModal = () => {
    setShowProfileModal(!showProfileModal);
  };

  const handleProfileClick = (profile: Profile) => {
    setProfileToDelete(profile);
    setUpdatedName(profile.name);
    setShowDeleteModal(true);
  };

  // Using local avatars temporarily
  const loadLocalAvatars = () => {
    const localAvatars: Avatar[] = [
      {
        avatarId: 1,
        avatarImageURL: require("./assets/Ava.webp"),
        avatarName: "Ava",
      },
      {
        avatarId: 2,
        avatarImageURL: require("./assets/Ben.webp"),
        avatarName: "Ben",
      },
      {
        avatarId: 3,
        avatarImageURL: require("./assets/Eve.webp"),
        avatarName: "Eve",
      },
      {
        avatarId: 4,
        avatarImageURL: require("./assets/Isabella.webp"),
        avatarName: "Isabella",
      },
      {
        avatarId: 5,
        avatarImageURL: require("./assets/Lucas.webp"),
        avatarName: "Lucas",
      },
      {
        avatarId: 6,
        avatarImageURL: require("./assets/Lumi.webp"),
        avatarName: "Lumi",
      },
      {
        avatarId: 7,
        avatarImageURL: require("./assets/Maple.webp"),
        avatarName: "Maple",
      },
      {
        avatarId: 8,
        avatarImageURL: require("./assets/Moth.webp"),
        avatarName: "Moth",
      },
      {
        avatarId: 9,
        avatarImageURL: require("./assets/Noah.webp"),
        avatarName: "Noah",
      },
      {
        avatarId: 10,
        avatarImageURL: require("./assets/Oliver.webp"),
        avatarName: "Oliver",
      },
    ];
    setAvatars(localAvatars);
  };

  // Fetch profiles
  const fetchProfileInfo = async () => {
    try {
      const response = await GetProfileInfo.get("", {
        headers: { Authorization: `Bearer ${userContext.token}` },
      });
      setProfiles(response.data.profiles);
    } catch (error) {
      toast.error("Error fetching profiles. Please try again later.");
    }
  };

  // Create new profile
  const createProfile = async () => {
    try {
      const { name } = profileData;

      if (!name) {
        toast.error("Please provide a name for the profile.");
        return;
      }

      if (!selectedAvatar) {
        toast.error("Please select an avatar for the profile.");
        return;
      }

      const payload = {
        name,
        avatarId: selectedAvatar.avatarId,
      };

      const response = await GetProfileInfo.post("", payload, {
        headers: {
          Authorization: `Bearer ${userContext.token}`,
          "Content-Type": "application/json",
        },
      });

      const profileCreated = response.data;
      toast.success("Profile created successfully!");

      // Update profiles/reset fields
      setProfiles((prev) => [...prev, profileCreated]);
      setProfileData({ name: "" });
      setSelectedAvatar(null);
      setShowProfileModal(false);
    } catch {
      toast.error("Error creating profile! Please try again later.");
    }
  };

  const deleteProfile = async () => {
    try {
      if (!profileToDelete) {
        toast.error("No profile selected for deletion.");
        return;
      }

      const response = await GetProfileInfo.post(
        `/${profileToDelete.uuId}/disable`,
        {
          headers: {
            Authorization: `Bearer ${userContext.token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Profile deleted successfully!");
        // Remove profile
        setProfiles((prev) =>
          prev.filter((profile) => profile.uuId !== profileToDelete.uuId)
        );
        setShowDeleteModal(false);
        setProfileToDelete(null);
      } else {
        toast.error("Failed to delete the profile. Please try again later.");
      }
    } catch (error) {
      toast.error("Error deleting profile! Please try again later.");
    }
  };

  const updateProfileName = async () => {
    try {
      if (!profileToDelete) {
        toast.error("No profile selected for update.");
        return;
      }

      if (!updatedName.trim()) {
        toast.error("Profile name cannot be empty.");
        return;
      }

      const payload = {
        name: updatedName,
      };

      const response = await GetProfileInfo.patch(
        `/${profileToDelete.uuId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userContext.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Profile updated successfully!");

        setProfiles((prev) =>
          prev.map((profile) =>
            profile.uuId === profileToDelete.uuId
              ? { ...profile, name: updatedName }
              : profile
          )
        );

        setShowDeleteModal(false);
        setProfileToDelete(null);
        setUpdatedName("");
      } else {
        toast.error("Failed to update the profile. Please tr again later.");
      }
    } catch (error) {
      toast.error("Error updating profile! Please try again later.");
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({ ...prev, [name]: value }));
  };

  // Load avatar and fetch profle
  useEffect(() => {
    loadLocalAvatars(); // Replace on CORS update
    fetchProfileInfo();
  }, []);

  return (
    <div className="tru-accinfo-container-bg bg-gradient_acc_info rounded-3xl border-[3px] border-solid border-x-[rgba(32,86,139,0.7)] border-b-[rgba(32,86,139,1)] border-t-[rgba(32,86,139,0.2)] p-4 shadow-[0px_0px_76px_0px_rgba(0,0,0,0.2)] md:p-8">
      {showProfileModal && (
        <ModalClosable
          isOpen={showProfileModal}
          setIsOpen={setShowProfileModal}
        >
          <div className="rounded-lg bg-white p-4">
            <h2 className="mb-8 text-center text-2xl font-bold">
              Create Profile
            </h2>
            <h2 className="mb-4 text-xl font-bold"> Profile Name</h2>
            <input
              type="text"
              name="name"
              value={profileData.name}
              onChange={handleInputChange}
              placeholder="Enter Name"
              className="mb-4 w-1/2 rounded-lg border border-gray-300 p-2 text-sm text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <h2 className="mb-4 text-xl font-bold"> Select Avatar</h2>
            <div className="grid grid-cols-2 gap-4">
              {avatars.map((avatar) => (
                <div
                  key={avatar.avatarId}
                  className={`relative flex h-12 w-12 cursor-pointer items-center justify-center rounded-full  ${
                    selectedAvatar?.avatarId === avatar.avatarId
                      ? "border-4 border-blue-500"
                      : "border border-gray-300"
                  }`}
                  onClick={() => setSelectedAvatar(avatar)}
                >
                  <img
                    src={avatar.avatarImageURL}
                    alt={avatar.avatarName}
                    className="h-full w-full rounded-full object-cover"
                  />
                </div>
              ))}
            </div>
            <div className="mt-8 flex justify-start">
              <Button btnType="green" onClick={createProfile}>
                Submit
              </Button>
            </div>
          </div>
        </ModalClosable>
      )}

      {showDeleteModal && profileToDelete && (
        <ModalClosable isOpen={showDeleteModal} setIsOpen={setShowDeleteModal}>
          <div className="rounded-lg bg-white p-4">
            <h2 className="mb-4 text-center text-xl font-bold">Edit Profile</h2>
            <div className="flex items-center justify-center">
              {avatars.find(
                (avatar) => avatar.avatarId === profileToDelete.avatarId
              ) && (
                <img
                  src={
                    avatars.find(
                      (avatar) => avatar.avatarId === profileToDelete.avatarId
                    )?.avatarImageURL
                  }
                  alt={profileToDelete.name}
                  className="h-16 w-16 rounded-full object-cover"
                />
              )}
            </div>
            <div className="mt-4">
              <label
                htmlFor="profileName"
                className="block text-lg font-medium"
              >
                Update Profile Name
              </label>
              <input
                type="text"
                id="profileName"
                value={updatedName}
                onChange={(e) => setUpdatedName(e.target.value)}
                className="mt-2 w-full rounded-lg border border-gray-300 p-2 text-sm text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {!confirmDelete ? (
              <div className="mt-8 flex justify-between">
                <Button btnType="red" onClick={() => setConfirmDelete(true)}>
                  Delete Profile
                </Button>
                <Button btnType="green" onClick={updateProfileName}>
                  Save Changes
                </Button>
              </div>
            ) : (
              <div className="mt-8">
                <p className="text-red-600 text-center text-lg font-medium">
                  Are you sure? This action cannot be undone!
                </p>
                <div className="mt-4 flex justify-between">
                  <Button
                    btnType="gray"
                    onClick={() => setConfirmDelete(false)}
                  >
                    Cancel
                  </Button>
                  <Button btnType="red" onClick={deleteProfile}>
                    Continue
                  </Button>
                </div>
              </div>
            )}
          </div>
        </ModalClosable>
      )}

      <h2 className="mb-8 text-2xl tracking-wide text-white sm:text-4xl">
        Manage Profiles
      </h2>
      <h2 className="text-start text-2xl font-bold text-white">
        Active Profiles
      </h2>
      <div className="mt-10 flex flex-wrap items-start justify-between gap-4 sm:flex-nowrap sm:gap-8">
        {/* Profiles Section */}
        <div className="flex flex-wrap gap-6 sm:gap-10">
          {profiles.map((profile) => {
            const avatar = avatars.find((a) => a.avatarId === profile.avatarId);
            return (
              <div
                key={profile.uuId}
                className="flex w-[45%] flex-col items-center sm:w-auto"
                onClick={() => handleProfileClick(profile)}
              >
                <div className="relative flex h-12 w-12 cursor-pointer items-center justify-center rounded-full duration-200 hover:scale-110 hover:shadow-[0_3px_20px_rgba(56,166,213,0.6)]">
                  {avatar ? (
                    <img
                      src={avatar.avatarImageURL}
                      alt={profile.name}
                      className="h-full w-full rounded-full object-cover"
                    />
                  ) : (
                    <div className="flex h-full w-full items-center justify-center rounded-full bg-gray-300 text-sm text-gray-600">
                      No Image
                    </div>
                  )}
                </div>
                <p className="mt-2 text-center text-sm font-medium text-white">
                  {profile.name}
                </p>
              </div>
            );
          })}
        </div>
        <div className="w-full sm:mt-4 sm:w-auto">
          <Button
            btnType="green"
            onClick={() => toggleModal()}
            className="w-full sm:w-auto"
          >
            + Add New Profile
          </Button>
        </div>
      </div>
    </div>
  );
}
