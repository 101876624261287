import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { EnvironmentConfigContext } from "../../config/Environment";
import TagManager from "react-gtm-module";
import Cookies from "js-cookie";
import SEO from "../../components/common/SEO";
import {
  GetOrCreateSessionAuthContext,
  Context,
  isAuthenticatedWithValidToken,
  SecureCookieAttributes,
  navigateTo,
  UserAuthContext,
  SetAuthTokenAsCookies,
} from "../../config/UserAuthContext";
import {
  MixPanelAnalyticsContext,
  Properties,
} from "../../components/analytics/Analytics";
import AccountServiceClient from "../../lib/ApiFunction";
import { toast } from "react-toastify";
import { useFeatureFlags } from "../../config/FeatureFlagContext";
import { PulseLoader } from "react-spinners";

// Components //
import AfterPurchaseSteps from "../../components/thank-you/AfterPurchaseSteps";
import Button from "../../components/common/Button";

// Images //
import QRCode from "../../global-assets/assets/Download-App-QR.webp";
import LeftImage from "./assets/thankyou-left-image.webp";
import RightImage from "./assets/thankyou-right-image.webp";
import MobileImage from "./assets/thankyou-mobile-image.webp";
import DownloadAppStar from "../../global-assets/assets/Download-App-Star.svg";
import AppStore from "../../global-assets/assets/AStore.webp";
import GooglePlay from "../../global-assets/assets/GPlay.webp";
import arrow from "../../global-assets/assets/Caret.svg";

interface Flextrack {
  invoked?: boolean;
  methods?: string[];
  factory?: any;
  init?: (advertiserId: string) => void;
  getClick?: () => void;
  track?: (data: any) => void;
  [key: string]: any;
}

declare global {
  interface Window {
    flextrack: Flextrack;
  }
}

/**
 * Payment Completed Page
 * @constructor
 */
const PaymentCompleted = () => {
  const analyticsContext = useContext(MixPanelAnalyticsContext);
  const config = useContext(EnvironmentConfigContext);

  const appleURL = config?.AppStoreURL();
  const googleURL = config?.PlayStoreURL();

  const osType = navigator.userAgent;
  const initialUserContext = GetOrCreateSessionAuthContext();
  const [userContext, setUserContext] = useState(initialUserContext);
  const GlobalUserId = Cookies.get("GlobalUserId");
  const subscriptionInfo = userContext.subscription.sources;
  const { SubscriptionAPI } = AccountServiceClient();
  const location = useLocation();

  const featureFlags = useFeatureFlags();
  const SignUpFlowOptimization =
    featureFlags["2024-09 - Yes Ladder Onboarding: Sign Up Flow Optimization"]
      ?.variationId;
  const [signUpFlowOptimization, setSignUpFlowOptimization] =
    useState<number>(1);
  const [testVariantLoading, setTestVariantLoading] = useState<boolean>(false);

  useEffect(() => {
    if (SignUpFlowOptimization) {
      setTestVariantLoading(true);
      switch (SignUpFlowOptimization) {
        case "1":
          setTestVariantLoading(false);
          break;
        case "2":
          setSignUpFlowOptimization(2);
          setTestVariantLoading(false);
          break;
        case "3":
          setSignUpFlowOptimization(3);
          setTestVariantLoading(false);
          break;
      }
    }
  }, [SignUpFlowOptimization]);

  const getSubscription = async () => {
    if (!isAuthenticatedWithValidToken()) return;

    if (!userContext?.subscription.isActive) {
      try {
        const res = await SubscriptionAPI.get("");
        const updatedContext = new UserAuthContext();
        updatedContext.subscription = res.data;
        updatedContext.communicationsOptIn = userContext.communicationsOptIn;
        updatedContext.token = userContext.token;
        updatedContext.uuId = userContext.uuId;
        updatedContext.verifyEmail = userContext.verifyEmail;
        updatedContext.sso = userContext.sso;

        setUserContext(updatedContext);
        SetAuthTokenAsCookies(
          JSON.stringify(updatedContext),
          Cookies.get("EmailAddress") || ""
        );

        if (res.data.isActive) {
          Cookies.set(
            Context.UserContext,
            JSON.stringify(updatedContext),
            SecureCookieAttributes
          );

          toast.success(
            <div>
              <b>Success!</b> Your payment has been received and your account
              has been created successfully.
            </div>,
            {
              position: "top-center",
            }
          );
        }
      } catch (error) {
        console.error("Error fetching subscription data:", error);
      }
    }
  };

  useEffect(() => {
    getSubscription();

    if (subscriptionInfo && subscriptionInfo.length > 0) {
      let attribution = localStorage.getItem("tru-attribution") as string;
      let campaignAttribution: Properties = {};
      if (attribution) {
        campaignAttribution = JSON.parse(attribution) as Properties;
      }

      campaignAttribution["price_id"] = subscriptionInfo[0].productId;
      analyticsContext.track(
        "Web: Stripe-Payment-Completed",
        campaignAttribution
      );
    }

    const params = new URLSearchParams(location.search);
    const sessionId = params.get("session_id");
    const freeTrial = Cookies.get("FreeTrial");
    const subsplash = Cookies.get("Subsplash");
    const hasSentSubscriptionSuccess = sessionStorage.getItem(
      "hasSentSubscriptionSuccess"
    );

    let subscriptionType = "unknown";

    if (freeTrial || subsplash) {
      subscriptionType = "trial";
    }

    if (!hasSentSubscriptionSuccess) {
      if (sessionId && subscriptionInfo?.length > 0) {
        // User came from Stripe
        TagManager.dataLayer({
          dataLayer: {
            event: "subscription_success",
            page_name: "accounts: subscription success",
            page_type: "accounts",
            os_type: osType,
            plan_type: subscriptionInfo[0]?.productId ?? "none",
            subscription_type: subscriptionType,
            custom_user_id: GlobalUserId || undefined,
          },
        });
        sessionStorage.setItem("hasSentSubscriptionSuccess", "true");
      } else if (!sessionId && !hasSentSubscriptionSuccess) {
        // User did not come from Stripe and the event has not been sent
        TagManager.dataLayer({
          dataLayer: {
            page_name: "accounts: subscription success",
            page_type: "accounts",
            os_type: osType,
            plan_type: subscriptionInfo[0]?.productId ?? "none",
            subscription_type: subscriptionType,
            custom_user_id: GlobalUserId || undefined,
          },
        });
        sessionStorage.setItem("hasSentSubscriptionSuccess", "true");

        if (freeTrial) {
          Cookies.remove("FreeTrial");
        }
        Cookies.remove("Subsplash");
      }
    }

    if (process.env.REACT_APP_ENVIRONMENT === "prod") {
      const script = document.createElement("script");
      script.src =
        "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=STmu7s";
      script.async = true;
      script.type = "text/javascript";
      document.body.appendChild(script);

      return () => {
        if (document.body.contains(script)) {
          document.body.removeChild(script);
        }
      };
    }
  }, [userContext]);

  const handleDownloadClick = (linkName: string, linkURL: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "select_content",
        content_type: "link",
        os_type: osType,
        link_name: linkName,
        link_url: linkURL,
        plan_type: subscriptionInfo[0]?.productId || "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });
    window.open(linkURL, "_blank");
  };

  const afterPurchaseSteps = [
    {
      customStep:
        signUpFlowOptimization === 1 ? (
          <div
            className="relative flex w-full flex-col gap-4 rounded-[30px] bg-white/[6%] p-5 text-start shadow-[0_0_41px_-1px_#001b3666] backdrop-blur-[3px] md:p-8 lg:gap-8"
            key="step1"
          >
            <img
              src={DownloadAppStar}
              alt="star"
              className="absolute -top-[20px] right-[20px] max-h-[30px] max-w-[50px] md:hidden"
            />
            <div className="flex gap-3 md:w-full">
              <div className="text-transparent flex min-w-[67px] justify-center bg-gradient_text bg-clip-text font-display text-8xl font-bold lg:min-w-[89px] lg:text-9xl lg:!leading-[0.8]">
                1
              </div>
              <div className="flex w-full items-center">
                <div className="flex flex-col gap-2">
                  <h6 className="font-display text-2xl font-bold text-white drop-shadow-[0_0_96px_#38a6d5bf]">
                    Download the app
                  </h6>
                  <p className="m-0 font-body text-base font-normal text-indigo-200">
                    Download TruPlay on your Apple or Android Devices.
                  </p>
                  <div className="hidden w-full items-center justify-start gap-2.5 md:flex">
                    <Link
                      id="auth_download_apple"
                      to={appleURL}
                      target="_blank"
                      onClick={(e) => handleDownloadClick("apple", appleURL)}
                    >
                      <img
                        src={AppStore}
                        alt="Apple store"
                        className="max-h-12"
                      />
                    </Link>
                    <Link
                      id="auth_download_google_play"
                      to={googleURL}
                      target="_blank"
                      onClick={(e) => handleDownloadClick("google", googleURL)}
                    >
                      <img
                        src={GooglePlay}
                        alt="Google play"
                        className="max-h-12"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="hidden justify-end lg:block">
                <img
                  src={QRCode}
                  alt="Download the app"
                  className="max-w-[100px]"
                />
              </div>
            </div>
            <div className="flex w-full gap-2.5 md:hidden">
              <Link
                id="auth_download_apple"
                to={appleURL}
                target="_blank"
                onClick={(e) => handleDownloadClick("apple", appleURL)}
              >
                <img src={AppStore} alt="Apple store" className="max-h-12" />
              </Link>
              <Link
                id="auth_download_google_play"
                to={googleURL}
                target="_blank"
                onClick={(e) => handleDownloadClick("google", googleURL)}
              >
                <img src={GooglePlay} alt="Google play" className="max-h-12" />
              </Link>
            </div>
          </div>
        ) : (
          <div
            key="Get started now"
            className="relative flex w-full gap-3 rounded-[30px] bg-white/[6%] p-4 text-start shadow-[0px_0px_80px_0px_#001B36] backdrop-blur-[3px] md:gap-6 md:p-8 lg:min-w-[800px]"
          >
            <img
              src={DownloadAppStar}
              alt="star"
              className="absolute -top-[20px] right-[20px] max-h-[30px] max-w-[50px] md:hidden"
            />
            <div className="text-transparent flex min-w-[67px] justify-center bg-gradient_text bg-clip-text font-display text-8xl font-bold lg:min-w-[89px] lg:text-9xl lg:!leading-[0.8]">
              1
            </div>
            <div className="flex w-full flex-col gap-2 lg:gap-4">
              <h6 className="font-display text-2xl font-bold text-white drop-shadow-[0_0_96px_#38a6d5bf] lg:text-3xl">
                Get started now
              </h6>
              <Button
                id="dynamic_app_store_link"
                btnType="green"
                onClick={() =>
                  handleDownloadClick(
                    "dynamic app store link",
                    "https://truplaygames.sng.link/Dlrpx/cq6n/7est"
                  )
                }
                className="w-full text-sm xs:text-base lg:max-w-[80%]"
              >
                Download TruPlay
              </Button>
            </div>
            <div className="hidden lg:inline">
              <img
                src={QRCode}
                alt="Download the app"
                className="max-w-[100px]"
              />
            </div>
          </div>
        ),
    },
    {
      step: "Sign In",
      directions:
        "Choose 'Log In' and sign in with your account email and password.",
    },
    {
      step: "Play in the Light!",
      directions: "Enjoy safe and uplifting games!",
    },
    {
      customStep: (
        <div
          key="Refer a Friend, get $25!"
          className="relative flex w-full flex-col gap-3 rounded-[30px] bg-white/[6%] p-4 text-start shadow-[0px_0px_80px_0px_#001B36] backdrop-blur-[3px] md:p-8 lg:min-w-[900px]"
        >
          <div className="flex w-full justify-between">
            <div className="flex gap-3 md:gap-6">
              <div className="text-transparent flex min-w-[67px] justify-center bg-gradient_text bg-clip-text font-display text-8xl font-bold lg:text-9xl lg:!leading-[0.8]">
                4
              </div>
              <div className="flex flex-col gap-2">
                <h6 className="font-display text-2xl font-bold text-white drop-shadow-[0_0_96px_#38a6d5bf] lg:text-3xl">
                  Refer a Friend, get $25!
                </h6>
                <p className="m-0 font-body text-base font-normal text-indigo-200 lg:text-xl">
                  Share TruPlay and earn cash!
                </p>
              </div>
            </div>
            <div className="self-start">
              <Button
                id="refer_a_friend_cta_desktop"
                onClick={() => navigateTo("/affiliate", true)}
                btnType="green"
                className="hidden w-full lg:inline"
                rightIcon={
                  <img
                    src={arrow}
                    alt="right-arrow"
                    className="ml-3 w-5 font-bold"
                  />
                }
              >
                Refer A Friend
              </Button>
            </div>
          </div>
          <Button
            id="refer_a_friend_cta_mobile"
            onClick={() => navigateTo("/affiliate", true)}
            btnType="green"
            className="w-full lg:hidden"
            rightIcon={
              <img
                src={arrow}
                alt="right-arrow"
                className="ml-3 w-5 font-bold"
              />
            }
          >
            Refer A Friend
          </Button>
        </div>
      ),
    },
  ];

  if (testVariantLoading) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-indigo-900">
        <PulseLoader color="#fff" />
      </div>
    );
  }

  return (
    <div id="test_thank_you_page" className="relative overflow-hidden">
      <SEO
        title="Thank You | Subscription Confirmed | Truplay Games"
        description="Thank you for subscribing! Download the app and start playing now."
        canonicalUrl="https://www.truplaygames.com/thank-you"
      />
      <div className="absolute bottom-0 left-0 z-[0] hidden 2xl:block">
        <img
          src={LeftImage}
          alt="Friends Playing"
          className="max-w-[600px] 3xl:max-w-[800px]"
        />
      </div>
      <div className="mx-auto min-h-screen max-w-[900px] px-6 pb-12 pt-24 lg:px-0 lg:pt-32">
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_0%_100%,_#68e0cf_0%,_#1f9cff_15%,transparent_30%)] opacity-20" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_100%_0%,_#652c80_0%,transparent_40%)] opacity-60" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_100%_100%,_#20568b_0%,_#002952_25%,transparent_40%)] opacity-50" />
        <div className="flex flex-col items-center gap-8 text-center">
          <div className="flex flex-col items-center gap-4">
            <h1 className="text-transparent bg-gradient_text bg-clip-text text-center font-display text-4xl font-bold lg:min-h-[100px] lg:text-5xl">
              Welcome to the
              <br className="hidden lg:inline" /> TruPlay Family!
            </h1>
            <p className="text-center font-body text-base font-normal text-white lg:text-3xl">
              Just a few simple steps to safe screen time.
            </p>
            <div className="flex w-full items-center justify-center 2xl:hidden">
              <img
                src={MobileImage}
                alt="Lucas and Friends"
                className="-mb-[45%] -mt-[38%] w-[210%] max-w-[1200px] sm:-mb-[40%] sm:-mt-[35%] homemd:-mb-[250px] homemd:-mt-[190px]"
              />
            </div>
          </div>

          <AfterPurchaseSteps steps={afterPurchaseSteps} />
        </div>
      </div>
      <div className="absolute bottom-0 right-0 z-[0] hidden 2xl:block">
        <img
          src={RightImage}
          alt="Lucas and Friends"
          className="max-w-[560px] 3xl:max-w-[700px]"
        />
      </div>
    </div>
  );
};

export default PaymentCompleted;
