import { lazy, Suspense } from "react";
import SEO from "../../../components/common/SEO";
import TermsContent from "./TermsContent";

export default function TermsAndConditions() {
  return (
    <>
      <div className="relative w-full overflow-hidden bg-[#00203f] p-[50px_20px] pt-[100px] md:p-[75px_20px] md:pt-[125px] lg:pb-[75px]">
        <div className="mx-auto max-w-[1440px]">
          <div className="mx-auto mb-0 flex max-w-[820px] flex-col items-center justify-start text-center">
            <div className="relative mb-0">
              <h2 className="text-[#37a6d4]">Terms and Conditions</h2>
            </div>
          </div>
        </div>
      </div>

      <SEO
        title="Terms and Conditions | TruPlay Games"
        canonicalUrl="https://www.truplaygames.com/terms-and-conditions"
        description="Read the terms and conditions for using TruPlay. Ensure you understand the rules and guidelines for accessing our Christian games, comics, and videos for a safe gaming experience."
      />
      
      <Suspense
        fallback={<div className="p-[50px_20px] text-center">Loading...</div>}
      >
        <TermsContent />
      </Suspense>
    </>
  );
}
