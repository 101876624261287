// Videos and Images //
import TeamBG from "../../global-assets/assets/teambg.webp";
import Curveblue from "../../global-assets/assets/curveblue.svg";
import BenefitsCTABar from "../../global-assets/assets/Lucas-Workshop 1.webp";

// Benefits Icons //
import ChaplainIcon from "../../global-assets/assets/icon-chaplain.svg";
import FamilyIcon from "../../global-assets/assets/icon-family.svg";
import MedicalIcon from "../../global-assets/assets/icon-medical.svg";
import DentalIcon from "../../global-assets/assets/icon-dental.svg";
import VisionIcon from "../../global-assets/assets/icon-vision.svg";
import CasualDressIcon from "../../global-assets/assets/icon-casual-dress.svg";
import PayIcon from "../../global-assets/assets/icon-pay.svg";
import SickLeaveIcon from "../../global-assets/assets/icon-sick-leave.svg";

const benefits = [
  { title: "Team Chaplain", iconSrc: ChaplainIcon },
  {
    title: "Paid Sick leave",
    iconSrc: SickLeaveIcon,
  },
  {
    title: "Medical Benefits",
    iconSrc: MedicalIcon,
  },
  {
    title: "Dental Benefits",
    iconSrc: DentalIcon,
  },
  {
    title: "Vision Benefits",
    iconSrc: VisionIcon,
  },
  {
    title: "Casual Dress",
    iconSrc: CasualDressIcon,
  },
  {
    title: "Competitive Pay",
    iconSrc: PayIcon,
  },
  {
    title: "Family Leave",
    iconSrc: FamilyIcon,
  },
];

export default function CareersBenefits() {
  return (
    <div className="relative z-0 overflow-hidden bg-white pb-[100px] pl-[20px] pr-[20px] pt-[50px] sm:pl-[25px] sm:pr-[25px] md:pb-[150px] md:pl-[30px] md:pr-[30px] md:pt-[75px] lg:pb-[200px] lg:pl-[50px] lg:pr-[50px] lg:pt-[125px]">
      <div className="mx-auto max-w-[1440px]">
        <div className="mx-auto mb-[30px] flex max-w-[820px] flex-col items-center justify-start text-center md:mb-[50px] lg:mb-[75px]">
          <div className="relative mb-[16px]">
            <h2 className=" text-cerulean-500">True Benefits</h2>
          </div>
          <div className="mb-0 max-w-[1040px]">
            <p className="mb-0">
              At TruPlay Games, we value the well-being of our employees. To
              this end, we include in our hiring package the following benefits
              as a way for building a platform for success for each member of
              our team:
            </p>
          </div>
        </div>
        <div className="mx-auto mb-[75px] max-w-[410px] md:mb-[100px] lg:max-w-[1140px]">
          <div
            role="list"
            className="grid auto-cols-fr grid-cols-2 justify-center gap-x-[13%] gap-y-[50px] sm:gap-x-[9%] md:grid-cols-4 md:gap-y-[75px]"
          >
            {benefits.map((benefit) => (
              <div role="listitem" key={benefit.title}>
                <div className="flex h-full flex-col items-center justify-between gap-y-[5px] sm:gap-y-[10px] md:gap-y-[15px]">
                  <img
                    src={benefit.iconSrc}
                    loading="lazy"
                    alt=""
                    className="max-h-[80%] sm:max-h-none"
                  />
                  <div className="text-center font-display text-[16px] font-bold text-[#00203f] sm:text-[18px]">
                    <div>{benefit.title}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className="relative flex flex-col items-center justify-center rounded-[40px] bg-cover bg-center bg-no-repeat p-[50px] px-[7%] sm:p-[75px] md:p-[100px]"
          style={{
            backgroundImage: `url('${BenefitsCTABar}')`,
          }}
        >
          <div className="flex flex-col items-stretch">
            <div className="mx-auto flex max-w-[820px] flex-col items-center justify-start text-center">
              <div className="relative mb-[16px]">
                <h2 className="text-white">We're Hiring</h2>
              </div>
              <div className="mb-[22px] max-w-[1040px] md:mb-[32px]">
                <p className="mb-0 text-white">
                  We need your help to build a children's subscription game
                  service that delivers high-quality, entertaining content - but
                  that's not enough - it also needs to carry God's Truth. <br />
                  <br />
                  If you're interested in joining the TruPlay mission please
                  start your journey{" "}
                  <a
                    className="ml-[5px] cursor-pointer whitespace-nowrap font-display text-[1.25rem] font-semibold capitalize tracking-[1.2px] text-[rgb(56,166,213)] no-underline hover:underline"
                    rel="noreferrer"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdea-NvJoLkYKlppv9xKWk-mG1Bgwt_itNXVGIa5wSbEpVzUw/viewform"
                    target="_blank"
                  >
                    HERE
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[3]">
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[linear-gradient(#fff,rgba(255,255,255,0.7))]"></div>
        <div className="absolute bottom-0 left-0 right-0 top-auto -z-[3]">
          <div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(#fff,rgba(255,255,255,0))]"></div>
          <img
            src={TeamBG}
            loading="lazy"
            sizes="100vw"
            alt=""
            className="w-[100%]"
          />
        </div>
      </div>
      <img
        src={Curveblue}
        loading="lazy"
        alt=""
        className="absolute bottom-[0%] left-[0%] right-[0%] top-auto z-[5] mb-[-1px] w-[100%] max-w-none"
      />
    </div>
  );
}
