import { navigateTo } from "../../../config/UserAuthContext";
import TruPlayLogo from "../../../global-assets/assets/truplay-logo-large.webp";
import Button from "../../common/Button";

interface PromiseKeepersNavBarProps {
  handleScroll: () => void;
}

const PromiseKeepersNavBar = ({ handleScroll }: PromiseKeepersNavBarProps) => {
  return (
    <>
      <link href="https://fonts.googleapis.com" rel="preconnect" />
      <link
        href="https://fonts.gstatic.com"
        rel="preconnect"
        crossOrigin="anonymous"
      />
      <div className="fixed bottom-auto left-0 right-0 top-0 z-[999] bg-global_nav_bg">
        <div className="relative mx-auto flex h-[104px] max-w-[1536px] items-center justify-between p-6 md:h-[114px]">
          <div className="flex items-center">
            <div className="cursor-pointer" onClick={() => navigateTo("/")}>
              <img
                src={TruPlayLogo}
                alt="Truplay-logo"
                className="w-[7.5rem] sm:w-[9.5rem] lg:w-48"
                id="unauth_tru_logo"
              />
            </div>
          </div>
          <Button
            id="nav_pk_join_btn"
            onClick={handleScroll}
            btnType="green"
            className="max-h-[56px] whitespace-nowrap"
          >
            Join the Mission
          </Button>
        </div>
      </div>
    </>
  );
};

export default PromiseKeepersNavBar;
